import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { withRouter } from 'react-router-dom'
import QueryString from 'query-string'

import * as S from './styles'
import * as Icon from '../Common/Icon'

const PortfolioSumMenu = {
  category: [
    {
      name: 'web',
      icon: <Icon.EnterpriseIcon />
    },
    {
      name: 'mobile',
      icon: <Icon.MobileIcon />
    },
    {
      name: 'blockchain',
      icon: <Icon.BlockchainIcon />
    },
    {
      name: 'desktop',
      icon: <Icon.DesktopIcon />
    },
    {
      name: 'game',
      icon: <Icon.JoyStickIcon />
    }
  ],
  industry: [
    {
      name: 'enterprise',
      icon: <Icon.EnterpriseIcon />
    },
    {
      name: 'finance',
      icon: <Icon.FinanceIcon />
    },
    {
      name: 'social',
      icon: <Icon.SocialIcon />
    },
    {
      name: 'healthcare',
      icon: <Icon.HealthcareIcon />
    },
    {
      name: 'dapp',
      icon: <Icon.DappIcon />
    },
    {
      name: 'crm',
      icon: <Icon.CRMIcon />
    },
    {
      name: 'ecommerce',
      icon: <Icon.MultimediaIcon />
    },
    {
      name: 'blog',
      icon: <Icon.BlogIcon />
    },
    {
      name: 'fitness',
      icon: <Icon.FitnessIcon />
    },
    {
      name: 'others',
      icon: <Icon.FinanceIcon />
    }
  ]
}

const PortfolioNavigation = () => {
  const { t } = useTranslation('menu')
  const history = useHistory()
  const location = useLocation()
  const [ category, setCategory ] = useState<string | null>(null)
  const [ industry, setIndustry ] = useState<string | null>(null)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setCategory(params.get('category'))
    setIndustry(params.get('industry'))
  }, [location])

  useEffect(() => {
    if (category || industry) {
      const query = {
        category,
        industry
      }
      history.push({
        pathname: '/portfolio',
        search: '?' + QueryString.stringify(query)
      })
    }
  }, [category, history, industry])

  return (
    <S.PortfolioSubmenu>
      <div className='category'>
        <h5>{t('portfolio.category.title')}</h5>
        <div className='category-list'>
          {
            PortfolioSumMenu.category.map((item) => (
              <S.SubMenuItem key={item.name} active={item.name === category} onClick={() => setCategory(item.name)}>
                {item.icon}
                <span>{t(`portfolio.category.${item.name}`)}</span>
              </S.SubMenuItem>
            ))
          }
        </div>
      </div>
      <div className='industry'>
        <h5>{t('portfolio.industry.title')}</h5>
        <div className='industry-list'>
          {
            PortfolioSumMenu.industry.map((item) => (
              <S.SubMenuItem key={item.name} active={item.name === industry} onClick={() => setIndustry(item.name)}>
                {item.icon}
                <span>{t(`portfolio.industry.${item.name}`)}</span>
              </S.SubMenuItem>
            ))
          }
        </div>
      </div>
    </S.PortfolioSubmenu>
  )
}
export default withRouter(PortfolioNavigation)
