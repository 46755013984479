import styled from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { HeaderBgImg } from '../../../components/Common/Image'

const MainSection = styled.section`
  position: relative;
  height: 1094px;
  background-image: url(${HeaderBgImg});
  background-position: 50% 67%;
  background-size: cover;
  margin-top: -130px;
  padding-top: 130px;

  .header {
    height: 130px;
  }

  .blog-img {
    height: 358px;
    background-size: cover;
    background-position: 50% 31%;
  }
`

const BlogContentPanel = styled.div<{reachEnd: boolean, reachStart: boolean}>`
  position: relative;
  max-width: 1312px;
  width: calc(100% - 120px);
  margin: -73px auto 0;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.light200};
  height: 1327px;
  min-height: 1327px;
  z-index: 10;
  padding: 80px 40px 80px 80px;
  box-shadow: 0 10px 34px rgba(210,210,210,0.5);

  .shadow {
    position: absolute;
    left: 0;
    width: 100%;
    height: 250px;
    transition: opacity ease-in-out 0.2s;

    ${props => props.reachEnd && `
      &.bottom {
        opacity: 0 !important;
      }
    `}

    ${props => props.reachStart && `
      &.top {
        opacity: 0 !important;
      }
    `}

    &.top {
      top: 52px;
      background: linear-gradient(180deg, ${props => props.theme.colors.light200} 10%, transparent);
      z-index: 3;
    }

    &.bottom {
      bottom: 52px;
      background: linear-gradient(0, ${props => props.theme.colors.light200} 10%, transparent);
    }
  }

  ${props => props.theme.breakpoints.down('xs')} {
    width: calc(100% - 30px);
    padding: 40px 10px 40px 40px;

    .shadow {
      &.top {
        top: 25px;
      }

      &.bottom {
        bottom: 25px;
      }
    }
  }
`

const BlogContent = styled(PerfectScrollbar)`
  padding-right: 40px;
  position: relative;

  .content {
    font: 20px/30px Roboto-Regular;
    white-space: pre-wrap;

    ${props => props.theme.breakpoints.down('xs')} {
      text-align: center;
    }
  }

  .loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    font: 32px/50px Roboto-Medium;
    margin-bottom: 15px;
    text-transform: uppercase;
    text-align: center;
  }

  .date {
    font: 12px/18px CircularStd-Book;
    color: ${props => props.theme.colors.grey700};
    margin-bottom: 30px;
  }

  p {
    font: 20px/30px Roboto-Regular;
    white-space: pre-wrap;
    margin-bottom: 40px;
  }

  .img-view {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 74px;

    p {
      margin: 0;
    }

    img {
      width: 100%;
      border-radius: 10px;
      margin: auto;
    }

    margin-bottom: 40px;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    padding-right: 20px;
    font: 14px/20px Roboto-Regular;

    .title {
      font: 24px/35px Roboto-Medium;
    }

    .img-view {
      grid-template-columns: 1fr;

      img {
        margin-top: 30px;
      }
    }

    p {
      font: 14px/20px Roboto-Regular;
    }
  }
`

const InterestsSection = styled.section`
  margin-top: 685px;

  .content {
    padding: 120px 135px 120px;

    .title {
      font: 32px/30px Roboto-Medium;
      color: ${props => props.theme.colors.white};
      margin-bottom: 80px;
      text-align: center;
    }

    .carousel-container {
      margin: 0 -11px;
    }

    ${props => props.theme.breakpoints.down('lg')} {
      padding: 120px 0 120px;
    }

    ${props => props.theme.breakpoints.down('sm')} {
      .carousel-container {
        margin: 0 20px !important;
      }
  }
`

const InterestBlogCard = styled.div`
  display: flex;
  height: 324px;
  flex-direction: column;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  transition: 0.1s;
  margin: 0 10px;

  .img-container {
    width: 100%;
    height: 152px;
    background-size: cover !important;
    background-position: 50% 50%;
    transition: 0.2s;
  }

  .content {
    background-color: ${props => props.theme.colors.white};
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    transition: 0.2s;
    position: relative;
    overflow: hidden;
    padding: 15px 12px;

    .title {
      font: 14px/18px CircularStd-Bold;
      color: ${props => props.theme.colors.blue500};
      margin: 0;
      text-transform: uppercase;
      transition: 0.2s;
      text-align: left;
    }

    .description {
      font: 12px/16px CircularStd-Book;
      color: ${props => props.theme.colors.grey600};
      margin: 10px 0 13px;
      transition: 0.2s;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -moz-box;
      -moz-line-clamp: 2;
      -moz-box-orient: vertical;
    }

    .date {
      font: 12px/18px CircularStd-Book;
      color: ${props => props.theme.colors.grey700};
      margin: 0;
      transition: 0.2s;
    }

    a {
      position: absolute;
      bottom: -40px;
      left: calc(50% - 105px);
      opacity: 0;
      transition: 0.2s;
    }
  }

  &:hover {
    border: 3px solid ${props => props.theme.colors.blue300};

    .content {
      background: linear-gradient(125deg, ${props => props.theme.colors.blue600}, ${props => props.theme.colors.blue400});

      .title {
        font: 16px/18px CircularStd-Bold;
        color: ${props => props.theme.colors.white};
        text-align: center;
      }

      .description, .date {
        opacity: 0;
        height: 0 !important;
      }

      a {
        bottom: 20px;
        opacity: 1;
      }
    }
  }
`

const EllipseBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;

  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;

    .big-ellipse {
      width: 986.69px;
      height: 986.69px;
      background: rgba(255, 255, 255, 0.05);
      filter: blur(24px);
      border-radius: 50%;
      position: absolute;
      bottom: -604px;
      left: -90px;
    }

    .small-ellipse {
      width: 496.61px;
      height: 496.61px;
      background: rgba(255, 255, 255, 0.03);
      filter: blur(24px);
      border-radius: 50%;
      position: absolute;
      bottom: -136px;
      right: 24px;
    }
  }
`

export {
  MainSection,
  BlogContentPanel,
  BlogContent,
  InterestsSection,
  InterestBlogCard,
  EllipseBackground
}
