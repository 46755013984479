import { IRootState } from '../resources/interfaces'

export const getBlogData = (state: IRootState) => {
  return {
    blogs: state.blogReducer.posts,
    categories: state.blogReducer.categories,
    topArticles: state.blogReducer.topArticles,
    category: state.blogReducer.category,
    subCategory: state.blogReducer.subCategory,
    totalCount: state.blogReducer.totalCount,
    pageIndex: state.blogReducer.pageIndex
  }
}

export const getBlogDetailData = (state) =>
  state.blogReducer.blogDetail
