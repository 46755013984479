import { MemberNew1Img, MemberNew2Img, MemberNew3Img, MemberNew4Img, MemberNew5Img, MemberNew6Img } from '../../components/Common/Image'

export const managementData = [
  {
    name: 'VALERY KRASOVSKY',
    img: MemberNew1Img,
    job: 'Chief Executive Officer, Co-founder',
    description: 'Valery is in charge of production and manages the day-to-day operations of Sigma production offices. This includes developing HR policy and legal compliance, hiring key employees and implementing IP protection best-practices.\n\nDmitry is startup enthusiast, inspiring and running Sigma’s successful startup Eventicious. Valery is in charge of production and manages the day-to-day operations of Sigma production offices. This includes developing HR policy and legal compliance, hiring key employees and implementing IP protection best-practices.\n\nDmitry is startup enthusiast, inspiring and running Sigma’s successful startup Eventicious.'
  },
  {
    name: 'DMITRY VARTANIAN',
    img: MemberNew2Img,
    job: 'Chief Financial Officer, Co-founder',
    description: 'Dmitry is in charge of production and manages the day-to-day operations of Sigma production offices. This includes developing HR policy and legal compliance, hiring key employees and implementing IP protection best-practices.\n\nDmitry is startup enthusiast, inspiring and running Sigma’s successful startup Eventicious.'
  },
  {
    name: 'OLESYA KHOKHOULIA',
    img: MemberNew3Img,
    job: 'Deputy CEO, Strategic Development',
    description: 'Olesya is in charge of production and manages the day-to-day operations of Sigma production offices. This includes developing HR policy and legal compliance, hiring key employees and implementing IP protection best-practices.\n\nDmitry is startup enthusiast, inspiring and running Sigma’s successful startup Eventicious.'
  },
  {
    name: 'VALERY KRASOVSKY',
    img: MemberNew4Img,
    job: 'Chief Executive Officer, Co-founder',
    description: 'Valery is in charge of production and manages the day-to-day operations of Sigma production offices. This includes developing HR policy and legal compliance, hiring key employees and implementing IP protection best-practices.\n\nDmitry is startup enthusiast, inspiring and running Sigma’s successful startup Eventicious.'
  },
  {
    name: 'DMITRY VARTANIAN',
    img: MemberNew5Img,
    job: 'Chief Financial Officer, Co-founder',
    description: 'Dmitry is in charge of production and manages the day-to-day operations of Sigma production offices. This includes developing HR policy and legal compliance, hiring key employees and implementing IP protection best-practices.\n\nDmitry is startup enthusiast, inspiring and running Sigma’s successful startup Eventicious.'
  },
  {
    name: 'OLESYA KHOKHOULIA',
    img: MemberNew6Img,
    job: 'Deputy CEO, Strategic Development',
    description: 'Olesya is in charge of production and manages the day-to-day operations of Sigma production offices. This includes developing HR policy and legal compliance, hiring key employees and implementing IP protection best-practices.\n\nDmitry is startup enthusiast, inspiring and running Sigma’s successful startup Eventicious.'
  },
]
