import styled from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'

interface TabItemProps {
  active: boolean
}

interface DescriptionPanelProps {
  scroll?: 'show' | 'hide',
  height?: number
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const TabList = styled.div`

`

const TabItem = styled.div<TabItemProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  h3 {
    font: 24px/45px Roboto-Bold;
    color: ${props => props.theme.colors.white};
    margin: 0;
    opacity: 0.5;
    transition: 0.3s;
  }

  .list-icon {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    opacity: 0.5;
    transition: all ease 0.3s;

    span {
      width: 11px;
      height: 11px;
      display: block;
      background: ${props => props.theme.colors.white};
      border-radius: 50%;
    }
  }

  &:hover {
    h3 {
      opacity: 1;
      color: ${props => props.theme.colors.yellow};
    }
  }

  ${props => props.active && `
    h3 {
      color: ${props.theme.colors.yellow};
      opacity: 1;
    }

    .list-icon {
      border: 1px solid ${props.theme.colors.yellow};
      opacity: 1;

      span {
        background: ${props.theme.colors.yellow};
      }
    }
  `}
`

const Scrollbar = styled(PerfectScrollbar)`
  &.ps--scrolling-y {
    .ps__rail-y {
      opacity: 1 !important;
    }
  }

  .ps__rail-x {
    display: none !important;
  }

  .ps__rail-y {
    width: 1px;
    background-color: white !important;
    margin-right: 2px;

    .ps__thumb-y {
      width: 5px;
      background-color: ${props => props.theme.colors.yellow} !important;
      right: 0 !important;
      border-radius: 5px !important;
      margin-right: -2px;
    }
  }
`;

const DescriptionPanel = styled(Scrollbar)<DescriptionPanelProps>`
  height: ${props => props.height || 400}px !important;
  text-align: left;
  overflow: hidden;
  padding-right: 20px;

  ${props => props.scroll === 'hide' && `
    height: unset !important;
  `}

  .title {
    font: 24px/45px Roboto-Bold;
    color: ${props => props.theme.colors.white};
    margin-bottom: 26px;
  }

  .description {
    font: 18px/27px Roboto-Medium;
    color: ${props => props.theme.colors.white};
    margin: 0;
    white-space: pre-wrap;
  }

  ${props => props.theme.breakpoints.down("sm")} {
    &.scrollbar-container {
      padding-right: 0;
    }

    .title, .description {
      text-align: center;
    }
  }
`

const MobileContainer = styled.div`
  padding: 0 30px;

  > div {
    margin-top: 30px;

    &:first-child {
      margin: 0;
    }
  }
`

export {
  Container,
  TabList,
  TabItem,
  DescriptionPanel,
  MobileContainer
}
