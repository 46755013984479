import styled from 'styled-components'
import { Paper as MuiPaper } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import PerfectScrollbar from 'react-perfect-scrollbar'

const Root = styled.div`
  display: flex;
  height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  &.MuiPaper-root {
    box-shadow: none;
    background: transparent;
  }
`;

const Container = styled(PerfectScrollbar)`
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background: linear-gradient(90deg, ${props => props.theme.colors.dark}, ${props => props.theme.colors.darkBlue});
  overflow: hidden;

  > div, section {
    z-index: 2;
  }

  > .ps__rail-x {
    display: none !important;
  }

  > .ps__rail-y {
    z-index: 999;
    opacity: 1 !important;
    width: 7px;
    background-color: ${props => props.theme.colors.grey500} !important;

    .ps__thumb-y {
      width: 7px;
      background-color: ${props => props.theme.colors.yellow} !important;
      right: 0 !important;
      border-radius: 10px !important;
    }
  }

  ${props => props.theme.breakpoints.down(450)} {
    max-height: unset !important;
  }
`

export { Root, AppContent, MainContent, Container }
