import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Hidden } from '@material-ui/core'

import * as S from './styles'
import { DevelopmentType } from '../../shared/enums'
import { Color } from '../../theme'
import {
  WebIconBg,
  ComputerImg,
  ShadowImg1,
  ShadowImg2,
  MobileIconBg,
  PhoneImg1,
  PhoneImg2,
  PhoneImg3,
  BlockchainIconBg,
  BlockchainImg,
  GameIconBg,
  GameImg,
  DesktopIconBg,
  DesktopImg
} from '../Common/Image'
import TestimonialsCarousel from '../TestimonialsCarousel'

interface WhatWeDoSectionProps {
  activeTab?: DevelopmentType,
  handleChangeTab?: (DevelopmentType) => void,
  pageName?: string
}

const colors = {
  web: Color.blue,
  mobile: Color.red,
  blockchain: Color.green,
  game: Color.orange,
  desktop: Color.purple
}

const WhatWeDoSection = (props: WhatWeDoSectionProps) => {
  const { t } = useTranslation('menu')
  const history = useHistory()
  const { activeTab, handleChangeTab, pageName } = props

  const handleClick = (key: string) => {
    if (handleChangeTab) {
      handleChangeTab(key)
    } else {
      history.push(`/${pageName || 'development'}/${key}`)
    }
  }

  const carouselOptions = {
    loop: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 3,
      },
      1400: {
        items: 3,
      },
    },
  }

  return (
    <>
      <Hidden lgDown>
        <S.Container>
          <S.DevelopmentItem className='web' active={activeTab === 'web'} color={activeTab ? colors[activeTab] : Color.white} onClick={() => handleClick('web')}>
            <div className='img-container'>
              <div className='bg-image'>
                <img src={WebIconBg} alt='webBgImg' />
              </div>
              <div className='character-image'>
                <img src={ComputerImg} alt='computerImg' />
              </div>
              <div className='shadow-image-1'>
                <img src={ShadowImg1} alt='shadowImg1' />
              </div>
              <div className='shadow-image-2'>
                <img src={ShadowImg2} alt='shadowImg2' />
              </div>
            </div>
            <div className='label'>
              <h5>{t('whatWeDo.web')}</h5>
            </div>
          </S.DevelopmentItem>
          <S.DevelopmentItem className='mobile' active={activeTab === 'mobile'} color={activeTab ? colors[activeTab] : Color.white} onClick={() => handleClick('mobile')}>
            <div className='img-container'>
              <div className='bg-image'>
                <img src={MobileIconBg} alt='webBgImg' />
              </div>
              <div className='character-image phone2'>
                <img src={PhoneImg2} alt='computerImg' />
              </div>
              <div className='character-image phone1'>
                <img src={PhoneImg1} alt='computerImg' />
              </div>
              <div className='character-image phone3'>
                <img src={PhoneImg3} alt='computerImg' />
              </div>
              <div className='shadow-image-1'>
                <img src={ShadowImg1} alt='shadowImg1' />
              </div>
              <div className='shadow-image-2'>
                <img src={ShadowImg2} alt='shadowImg2' />
              </div>
            </div>
            <div className='label'>
              <h5>{t('whatWeDo.mobile')}</h5>
            </div>
          </S.DevelopmentItem>
          <S.DevelopmentItem className='blockchain' active={activeTab === 'blockchain'} color={activeTab ? colors[activeTab] : Color.white} onClick={() => handleClick('blockchain')}>
            <div className='img-container'>
              <div className='bg-image'>
                <img src={BlockchainIconBg} alt='blockchainBgImg' />
              </div>
              <div className='character-image'>
                <img src={BlockchainImg} alt='blockchainImg' />
              </div>
              <div className='shadow-image-1'>
                <img src={ShadowImg1} alt='shadowImg1' />
              </div>
              <div className='shadow-image-2'>
                <img src={ShadowImg2} alt='shadowImg2' />
              </div>
            </div>
            <div className='label'>
              <h5>{t('whatWeDo.blockchain')}</h5>
            </div>
          </S.DevelopmentItem>
          <S.DevelopmentItem className='game' active={activeTab === 'game'} color={activeTab ? colors[activeTab] : Color.white} onClick={() => handleClick('game')}>
            <div className='img-container'>
              <div className='bg-image'>
                <img src={GameIconBg} alt='gameBgImg' />
              </div>
              <div className='character-image'>
                <img src={GameImg} alt='gameImg' />
              </div>
              <div className='shadow-image-1'>
                <img src={ShadowImg1} alt='shadowImg1' />
              </div>
              <div className='shadow-image-2'>
                <img src={ShadowImg2} alt='shadowImg2' />
              </div>
            </div>
            <div className='label'>
              <h5>{t('whatWeDo.game')}</h5>
            </div>
          </S.DevelopmentItem>
          <S.DevelopmentItem className='desktop' active={activeTab === 'desktop'} color={activeTab ? colors[activeTab] : Color.white} onClick={() => handleClick('desktop')}>
            <div className='img-container'>
              <div className='bg-image'>
                <img src={DesktopIconBg} alt='desktopBgImg' />
              </div>
              <div className='character-image'>
                <img src={DesktopImg} alt='desktopImg' />
              </div>
              <div className='shadow-image-1'>
                <img src={ShadowImg1} alt='shadowImg1' />
              </div>
              <div className='shadow-image-2'>
                <img src={ShadowImg2} alt='shadowImg2' />
              </div>
            </div>
            <div className='label'>
              <h5>{t('whatWeDo.desktop')}</h5>
            </div>
          </S.DevelopmentItem>
        </S.Container>
      </Hidden>
      <Hidden xlUp>
        <S.CarouselContainer>
          <TestimonialsCarousel
            key='carousel'
            options={carouselOptions}
          >
            <S.DevelopmentItem key='web' className='web' active={activeTab === 'web'} color={activeTab ? colors[activeTab] : Color.white} onClick={() => handleClick('web')}>
              <div className='img-container'>
                <div className='bg-image'>
                  <img src={WebIconBg} alt='webBgImg' />
                </div>
                <div className='character-image'>
                  <img src={ComputerImg} alt='computerImg' />
                </div>
                <div className='shadow-image-1'>
                  <img src={ShadowImg1} alt='shadowImg1' />
                </div>
                <div className='shadow-image-2'>
                  <img src={ShadowImg2} alt='shadowImg2' />
                </div>
              </div>
              <div className='label'>
                <h5>{t('whatWeDo.web')}</h5>
              </div>
            </S.DevelopmentItem>
            <S.DevelopmentItem key='mobile' className='mobile' active={activeTab === 'mobile'} color={activeTab ? colors[activeTab] : Color.white} onClick={() => handleClick('mobile')}>
              <div className='img-container'>
                <div className='bg-image'>
                  <img src={MobileIconBg} alt='webBgImg' />
                </div>
                <div className='character-image phone2'>
                  <img src={PhoneImg2} alt='computerImg' />
                </div>
                <div className='character-image phone1'>
                  <img src={PhoneImg1} alt='computerImg' />
                </div>
                <div className='character-image phone3'>
                  <img src={PhoneImg3} alt='computerImg' />
                </div>
                <div className='shadow-image-1'>
                  <img src={ShadowImg1} alt='shadowImg1' />
                </div>
                <div className='shadow-image-2'>
                  <img src={ShadowImg2} alt='shadowImg2' />
                </div>
              </div>
              <div className='label'>
                <h5>{t('whatWeDo.mobile')}</h5>
              </div>
            </S.DevelopmentItem>
            <S.DevelopmentItem key='blockchain' className='blockchain' active={activeTab === 'blockchain'} color={activeTab ? colors[activeTab] : Color.white} onClick={() => handleClick('blockchain')}>
              <div className='img-container'>
                <div className='bg-image'>
                  <img src={BlockchainIconBg} alt='blockchainBgImg' />
                </div>
                <div className='character-image'>
                  <img src={BlockchainImg} alt='blockchainImg' />
                </div>
                <div className='shadow-image-1'>
                  <img src={ShadowImg1} alt='shadowImg1' />
                </div>
                <div className='shadow-image-2'>
                  <img src={ShadowImg2} alt='shadowImg2' />
                </div>
              </div>
              <div className='label'>
                <h5>{t('whatWeDo.blockchain')}</h5>
              </div>
            </S.DevelopmentItem>
            <S.DevelopmentItem key='game' className='game' active={activeTab === 'game'} color={activeTab ? colors[activeTab] : Color.white} onClick={() => handleClick('game')}>
              <div className='img-container'>
                <div className='bg-image'>
                  <img src={GameIconBg} alt='gameBgImg' />
                </div>
                <div className='character-image'>
                  <img src={GameImg} alt='gameImg' />
                </div>
                <div className='shadow-image-1'>
                  <img src={ShadowImg1} alt='shadowImg1' />
                </div>
                <div className='shadow-image-2'>
                  <img src={ShadowImg2} alt='shadowImg2' />
                </div>
              </div>
              <div className='label'>
                <h5>{t('whatWeDo.game')}</h5>
              </div>
            </S.DevelopmentItem>
            <S.DevelopmentItem key='desktop' className='desktop' active={activeTab === 'desktop'} color={activeTab ? colors[activeTab] : Color.white} onClick={() => handleClick('desktop')}>
              <div className='img-container'>
                <div className='bg-image'>
                  <img src={DesktopIconBg} alt='desktopBgImg' />
                </div>
                <div className='character-image'>
                  <img src={DesktopImg} alt='desktopImg' />
                </div>
                <div className='shadow-image-1'>
                  <img src={ShadowImg1} alt='shadowImg1' />
                </div>
                <div className='shadow-image-2'>
                  <img src={ShadowImg2} alt='shadowImg2' />
                </div>
              </div>
              <div className='label'>
                <h5>{t('whatWeDo.desktop')}</h5>
              </div>
            </S.DevelopmentItem>
          </TestimonialsCarousel>
        </S.CarouselContainer>
      </Hidden>
    </>
  )
}

export default WhatWeDoSection
