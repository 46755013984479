import styled from 'styled-components'
import { Button as AButton } from 'antd'

import { Button } from '../Common/Button'

const ITEM_WIDTH = 372
const ITEM_HEIGHT = 486
const ACTIVE_ITEM_WIDTH = 404
const ACTIVE_ITEM_HEIGHT = 582

interface ItemProps {
  active?: boolean,
  hide?: boolean
}

const Container = styled.div`
  flex: 1;
  width: 0;
  display: flex;
  position: relative;

  > div {
    margin-right: 24px;

    &:last-child {
      margin: 0;
    }
  }

  .dots {
    position: absolute;
    bottom: 30px;
    right: 305px;
    display: flex;
    z-index: 11;

    .dot {
      width: 26px;
      height: 26px;
      border: 1px solid transparent;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      transition: 0.3s;

      &:last-child {
        margin: 0;
      }

      span {
        background-color: ${props => props.theme.colors.light300};
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 50%;
      }

      &.active {
        border-color: ${props => props.theme.colors.yellow} !important;

        span {
          background-color: ${props => props.theme.colors.yellow} !important;
        }
      }
    }
  }
`

const Item = styled.div<ItemProps>`
  position: relative;
  overflow: hidden;
  min-width: ${ITEM_WIDTH}px;
  height: ${ITEM_HEIGHT}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  ${props => props.theme.breakpoints.down(400)} {
    min-width: unset;
    width: 100%;
  }

  ${props => props.active && `
    min-width: ${ACTIVE_ITEM_WIDTH}px !important;
    height: ${ACTIVE_ITEM_HEIGHT}px !important;
  `}

  ${props => props.hide && `
    opacity: 0;
  `}

  .footer {
    display: flex;
    align-items: flex-end;
    z-index: 2;

    .card {
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(10px);
      border-radius: 10px;
      padding: 22px;
      width: 217px;

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        h4 {
          font: 15px/18px Roboto-Medium;
          color: ${props => props.theme.colors.grey300};
          text-transform: uppercase;
          margin: 0;
        }

        span {
          height: 1px;
          width: 27px;
          background-color: ${props => props.theme.colors.grey400};
          margin: 0 8px;
        }
      }

      .description {
        font: 16px/22px Roboto-Regular;
      }
    }
  }
`

const NextButton = styled(AButton)`
  width: 48px !important;
  height: 48px !important;
  border-radius: 50% !important;
  position: absolute !important;
  right: 43px;
  bottom: 250px;
  transition: 0.3s;
  border: none !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    margin-top: 5px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.yellow} !important;
    color: ${props => props.theme.colors.blue600} !important;
  }
`

const ArrowButton = styled(Button)`
  border-radius: 10px !important;
  width: 48px !important;
  height: 48px !important;
`

const MobileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;

  > div {
    margin:  0 auto;
  }
`

export { Container, Item, NextButton, ArrowButton, MobileContainer, ITEM_WIDTH, ITEM_HEIGHT, ACTIVE_ITEM_HEIGHT, ACTIVE_ITEM_WIDTH }
