import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import moment from 'moment'
import { Spin } from 'antd'

import TestimonialsCarousel from '../../../components/TestimonialsCarousel'
import { getBlogDetailData } from '../../../selectors'

import * as S from './styles'
import { LinkButton } from '../../../components/Common/Button'
import { getBlogDetail } from '../../../redux/actions/blogActions'

const BlogDetail = (props) => {
  const { t } = useTranslation('blog')
  const blogDetail = useSelector(getBlogDetailData)
  const dispatch = useDispatch()
  const location = useLocation()
  const [ reachEnd, setReachEnd ] = useState(false)
  const [ reachStart, setReachStart ] = useState(true)
  const [ loading, setLoading ] = useState(true)
  const [ scrolled, setScrolled ] = useState(false)

  useEffect(() => {
    const blogId = props.match.params.id
    if (!blogId) return

    const getBlog = async () => {
      await dispatch(getBlogDetail(blogId))
      setLoading(false)
    }
    getBlog().then()
  }, [dispatch, props.match.params.id])

  useEffect(() => {
    setLoading(true)
    setScrolled(false)
  }, [location])

  const carouselOptions = {
    loop: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 3,
      },
      1400: {
        items: 4,
      },
    },
  }

  const handleReachEvent = (reach: 'start' | 'end' | 'moving') => {
    if (loading) return
    if (reach === 'start') {
      setReachStart(true)
      setReachEnd(false)
    } else if (reach === 'end') {
      setReachStart(false)
      setReachEnd(true)
    } else {
      setScrolled(true)
      setReachEnd(false)
      setReachStart(false)
    }
  }
  return (
    <React.Fragment>
      <S.MainSection>
        <div className='blog-img' style={{backgroundImage: !loading ? `url(${blogDetail.img})` : ''}} />
        <S.BlogContentPanel reachEnd={reachEnd} reachStart={reachStart || !scrolled}>
          <div className={'shadow top'} />
          <S.BlogContent
            onYReachStart={() => handleReachEvent('start')}
            onYReachEnd={() => handleReachEvent('end')}
            onScrollY={(e) => handleReachEvent('moving')}
          >
            {
              loading
              ?
                <div className='loader'>
                  <Spin
                    spinning={ true }
                    size='large'
                  />
                </div>
              :
                <>
                  <h3 className='title'>{blogDetail.title}</h3>
                  <p className='date'>{moment(blogDetail.createdAt).format('DD MMMM, YYYY')}</p>
                  <div className='content' dangerouslySetInnerHTML={{__html: blogDetail.description}} />
                </>

            }
          </S.BlogContent>
          <div className='shadow bottom' />
        </S.BlogContentPanel>
      </S.MainSection>
      <S.InterestsSection>
        <div className='container'>
          {
            blogDetail && blogDetail.interestedBlogs?.length > 0 &&
            <div className='content'>
              <h3 className='title'>{t('youMaybeInterestedIn')}</h3>
              <div className='carousel-container'>
                <TestimonialsCarousel
                  options={carouselOptions}
                >
                  {
                    blogDetail.interestedBlogs.map((blog) => (
                      <S.InterestBlogCard key={blog.id}>
                        <div className='img-container' style={{background: `url(${blog.img})`}}/>
                        <div className='content'>
                          <p className='title'>{blog.title}</p>
                          <p className='description'>{blog.description}</p>
                          <p className='date'>{moment(blog.createdAt).format('DD MMM YYYY')}</p>
                          <LinkButton to={`/blog/${blog.id}`}>Read More</LinkButton>
                        </div>
                      </S.InterestBlogCard>
                    ))
                  }
                </TestimonialsCarousel>
              </div>
            </div>
          }
        </div>
      </S.InterestsSection>
      <S.EllipseBackground>
        <div className='content'>
          <div className='big-ellipse'/>
          <div className='small-ellipse'/>
        </div>
      </S.EllipseBackground>
    </React.Fragment>
  )
}

export default BlogDetail
