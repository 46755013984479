// export declare type DevelopmentType = 'web' | 'mobile' | 'blockchain' | 'game' | 'desktop'

export enum DevelopmentType {
  WEB = 'web',
  MOBILE = 'mobile',
  BLOCKCHAIN = 'blockchain',
  GAME = 'game',
  DESKTOP = 'desktop'
}

export enum IndustryType {
  ENTERPRISE = 'enterprise',
  FINANCE = 'finance',
  SOCIAL = 'social',
  HEALTHCARE = 'healthcare',
  DAPP = 'dapp',
  CRM = 'crm',
  ECOMMERCE = 'ecommerce',
  BLOG = 'blog',
  FITNESS = 'fitness',
  OTHERS = 'others'
}

export enum Skill {
  GO = 'Go',
  PYTHON = 'Python',
  RUBY = 'Ruby',
  PHP = 'PHP',
  LARAVEL = 'Laravel',
  SYMFONY = 'Symfony',
  CODEIGNITER = 'CodeIgniter',
  WORDPRESS = 'WordPress',
  NODE = 'Node.js',
  EXPRESS = 'Express.js',
  REACT = 'React.js',
  VUE = 'Vue.js',
  ANGULAR = 'Angular.js',
  TYPESCRIPT = 'Typescript',
  JAVASCRIPT = 'Javascript',
  HTML = 'HTML',
  CSS = 'CSS',
  ANDROID = 'Android',
  IOS = 'iOS',
  UNITY = 'Unity',
  C = 'C',
  CPP = 'C++',
  CSHARP = 'C#'
}
