import Http from './api'

export const getPosts = (page: number, size: number) => {
  return Http.get('/posts', { page, size })
}

export const getTopPosts = (size: number) => {
  return Http.get('/top_posts', { size });
}

export const getPost = (id: number) => {
  return Http.get('/post', { id })
}

export const getRecentPosts = (id: number, size: number) => {
  return Http.get('/recent_posts', { id, size })
}
