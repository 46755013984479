import React, { useRef, ReactNode } from 'react';
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import * as S from './styles'
import { Owl_Carousel_Options } from 'react-owl-carousel3'

interface CarouselProps {
  children: ReactNode[],
  options?: Owl_Carousel_Options
}
const defaultOptions = {
  loop: true,
  nav: false,
  dots: true,
  autoplayHoverPause: true,
  autoplay: false,
  autoplayTimeout: 10,
  slideBy: 'page',
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 1,
    },
    992: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

const Index = (props: CarouselProps) => {
  const { children, options } = props;
  const carousel = useRef(null)

  const onPrev = () => {
    if (carousel && carousel.current) {
      // @ts-ignore
      carousel.current.prev();
    }
  }

  const onNext = () => {
    if (carousel && carousel.current) {
      // @ts-ignore
      carousel.current.next();
    }
  }

  const carouselOptions = { ...defaultOptions, ...options }

  return (
    <S.Container dotsEnabled={carouselOptions.dots}>
      <S.IconButton
        className='prev-button'
        onClick={onPrev}
        icon={<LeftOutlined />}
      />
      <S.Carousel
        className='owl-carousel owl-theme'
        ref={carousel}
        {...carouselOptions}
      >
        {
          children && children.map((chip) => chip)
        }
      </S.Carousel>
      <S.IconButton
        className='next-button'
        onClick={onNext}
        icon={<RightOutlined />}
      />
    </S.Container>
  );
};

export default Index;
