import React, { useRef, useState } from 'react'
import { Hidden } from '@material-ui/core'
import AwesomeSliderStyles from 'react-awesome-slider/src/styles'

import { managementData } from '../../pages/Company/data'
import * as S from './styles'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'

interface ManagementCardType {
  name: string,
  img: string,
  job: string,
  description: string
}

interface ManagementSliderProps {
  data: ManagementCardType[]
}

const ManagementSlider = (props: ManagementSliderProps) => {
  const slider = useRef(null)
  const { data } = props
  const [ isFirstSlide, setIsFirstSlide ] = useState(true)
  const [ isLastSlide, setIsLastSlide ] = useState(false)

  const groups: ManagementCardType[][] = []

  if (data && data.length > 0) {

    for (let i = 0; i < data.length; i += 3) {
      groups.push(data.slice(i, i + 3));
    }
  }

  const onPrev = () => {
    if (slider && slider.current) {
      // @ts-ignore
      slider.current.clickPrev();
    }
  }

  const onNext = () => {
    if (slider && slider.current) {
      // @ts-ignore
      slider.current.clickNext();
    }
  }

  const handleSlider = (e) => {
    const { currentIndex, slides } = e
    setIsFirstSlide(currentIndex === 0)
    setIsLastSlide(currentIndex === slides - 1)
  }

  return (
    <S.Container>
      <Hidden smDown>
        <S.Slider
          cssModule={AwesomeSliderStyles}
          ref={slider}
          buttons={false}
          onTransitionEnd={handleSlider}
        >
          { groups.map((group, index) => (
            <div key={index}>
              <S.ManagementCardList>
                {
                  group.map((item) => (
                    <S.ManagementCard key={item.name}>
                      <div className='card-container'>
                        <div className='img-container'>
                          <img src={item.img} alt='user.png' />
                        </div>
                        <div className='card-content'>
                          <p className='name'>{item.name}</p>
                          <div className='divider' />
                          <p className='job'>{item.job}</p>
                          <p className='description'>{item.description}</p>
                        </div>
                      </div>
                    </S.ManagementCard>
                  ))
                }
              </S.ManagementCardList>
            </div>
          )) }
        </S.Slider>
        <S.Toolbar>
          <S.ArrowButton onClick={onPrev} disabled={isFirstSlide}><ArrowLeftOutlined/></S.ArrowButton>
          <S.ArrowButton onClick={onNext} disabled={isLastSlide}><ArrowRightOutlined/></S.ArrowButton>
        </S.Toolbar>
      </Hidden>
      <Hidden mdUp>
        <S.MobileList>
          {
            managementData.map((item, index) => (
              <S.ManagementCard key={index}>
                <div className='card-container'>
                  <div className='img-container'>
                    <img src={item.img} alt='user.png' />
                  </div>
                  <div className='card-content'>
                    <p className='name'>{item.name}</p>
                    <div className='divider' />
                    <p className='job'>{item.job}</p>
                    <p className='description'>{item.description}</p>
                  </div>
                </div>
              </S.ManagementCard>
            ))
          }
        </S.MobileList>
      </Hidden>
    </S.Container>
  )
}

export default ManagementSlider
