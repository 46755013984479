import { Button as AButton } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Button = styled(AButton)`
  background-color: ${props => props.theme.colors.yellow} !important;
  color: ${props => props.theme.colors.grey100} !important;
  height: 50px !important;
  width: 210px !important;
  border-radius: 30px !important;
  border: 1px solid ${props => props.theme.colors.yellow} !important;
  transition: all ease 0.3s;

  span {
    font: 20px/20px Roboto-Bold !important;
  }

  &:hover {
    background-color: transparent !important;
    color: ${props => props.theme.colors.white} !important;
  }
`

export const OutlinedButton = styled(AButton)`
  background-color: transparent !important;
  color: ${props => props.theme.colors.yellow} !important;
  height: 50px !important;
  width: 210px !important;
  border-radius: 30px !important;
  border: 1px solid ${props => props.theme.colors.yellow} !important;
  transition: all ease 0.3s;

  span {
    font: 20px/20px Roboto-Bold !important;
  }

  &:hover {
    background-color: ${props => props.theme.colors.yellow} !important;
    color: ${props => props.theme.colors.grey100} !important;
  }
`

export const LinkButton = styled(Link)`
  background-color: ${props => props.theme.colors.yellow} !important;
  color: ${props => props.theme.colors.grey100} !important;
  height: 50px !important;
  width: 210px !important;
  border-radius: 30px !important;
  border: 1px solid ${props => props.theme.colors.yellow} !important;
  transition: all ease 0.3s;
  font: 20px/20px Roboto-Bold !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: transparent !important;
    color: white !important;
  }
`
