import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Menu, Spin } from 'antd'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import TestimonialsCarousel from '../../components/TestimonialsCarousel'
import { Button, LinkButton } from '../../components/Common/Button'

import * as S from './styles'
import { SectionTitle } from '../../components/Common/CommonStyled'
import { BlogTypes } from './data'
import { getBlogs, getCategories, getTopArticles } from '../../redux/actions/blogActions'
import { getBlogData } from '../../selectors'

const { SubMenu } = Menu

const BlogPage = () => {
  const { t } = useTranslation('blog')
  const blogData = useSelector(getBlogData)
  const dispatch = useDispatch()
  const history = useHistory()
  const { blogs, categories, topArticles, category, subCategory, totalCount, pageIndex } = blogData
  const [ openKeys, setOpenKeys ] = useState(['otherHeadings'])
  const [ currentPageIndex, setCurrentPageIndex ] = useState(pageIndex)
  const [ innerWidth, setInnerWidth ] = useState(window.innerWidth)
  const [ loading, setLoading ] = useState(false)

  const setWindowWidth = () => {
    setInnerWidth(window.innerWidth)
  }

  const getBlog = useCallback(async (category: string, subCategory: string, pageIndex: number) => {
    setLoading(true)
    await dispatch(getBlogs(category, subCategory, pageIndex))
    setLoading(false)
  }, [dispatch])

  useEffect(() => {
    dispatch(getTopArticles(7))
    dispatch(getCategories())

    window.addEventListener('resize', setWindowWidth)
    return () => window.removeEventListener('resize', setWindowWidth)
  }, [dispatch])

  useEffect(() => {
    if (!categories.length || blogs.length !== 0) return
    const defaultCategory = categories[0].category
    const defaultSubCategory = categories[0].subCategories[0]
    getBlog(defaultCategory, defaultSubCategory, 1).then()
  }, [blogs.length, categories, getBlog])

  const onOpenChange = keys => {
    const rootSubmenuKeys = categories.map(item => item.category)
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const onMenuItemSelect = (e) => {
    const selectedKey = e?.keyPath && e.keyPath.length ? e.keyPath[0] : null
    if (selectedKey && selectedKey.indexOf('.') > 0) {
      const category = selectedKey.split('.')[0]
      const subCategory = selectedKey.split('.')[1]
      getBlog(category, subCategory, currentPageIndex).then()
    }
  }

  const handlePaginationChange = (pageIndex: number) => {
    getBlog(category, subCategory, pageIndex).then()
    setCurrentPageIndex(pageIndex)
  }

  const getDirectionOfBlogCard = useCallback((index: number) => {
    if (innerWidth > 678) {
      return index === 2 ? 'horizontal' : 'vertical'
    } else if (innerWidth > 600) {
      return 'horizontal'
    } else {
      return 'vertical'
    }
  }, [innerWidth])

  const gotoDetailPage = (id) => {
    history.push(`/blog/${id}`)
  }

  return (
    <React.Fragment>
      <S.MainSection>
        <div className='container'>
          <div className='content'>
            <div className='header'>
              <SectionTitle>{t('title')}</SectionTitle>
              <div className='carousel-container'>
                <TestimonialsCarousel
                  options={{dots: false}}
                >
                  {
                    BlogTypes.map((item) => (
                      <S.BlogTypeCard key={item.type}>
                        <div className='img-container'>
                          <img src={item.img} alt={`${item.type}-blog-img`} />
                        </div>
                        <div className='card-content'>
                          <h3 className='name'>{t(`development.${item.type}`)}</h3>
                        </div>
                      </S.BlogTypeCard>
                    ))
                  }
                </TestimonialsCarousel>
              </div>
            </div>
            <div className='body'>
              <div className='left'>
                <S.BlogListPanel>
                  {
                    (loading || !blogs.length) &&
                      <div className='loader'>
                        <Spin
                          spinning={ true }
                          size='large'
                        />
                      </div>
                  }
                  <div className='heading'>
                    <p className='type'>{category}</p>
                    <p className='category'>{subCategory}</p>
                  </div>
                  <div className='blog-list'>
                    {
                      blogs.length > 0 && blogs.map((blog, index) => {
                        return (
                          <S.BlogCard key={blog.id} direction={getDirectionOfBlogCard(index)}>
                            <div className='img-container' style={{backgroundImage: `url(${blog.img})`}}/>
                            <div className='content'>
                              <p className='title'>{blog.title}</p>
                              <p className='description'>{blog.description}</p>
                              <p className='date'>{moment(blog.createdAt).format('DD MMM YYYY')}</p>
                              <LinkButton to={`/blog/${blog.id}`}>Read More</LinkButton>
                            </div>
                          </S.BlogCard>
                        )
                      })
                    }
                  </div>
                  <S.PaginationWrapper disabled={totalCount < 6}>
                    <S.PaginationButton onClick={() => handlePaginationChange(currentPageIndex - 1)} disabled={currentPageIndex === 1}>
                      <LeftOutlined/>
                    </S.PaginationButton>
                    <S.Pagination
                      current={currentPageIndex}
                      showSizeChanger={false}
                      defaultCurrent={3}
                      pageSize={5}
                      total={totalCount}
                      hideOnSinglePage={true}
                      onChange={handlePaginationChange}
                    />
                    <S.PaginationButton onClick={() => handlePaginationChange(currentPageIndex + 1)} disabled={currentPageIndex === Math.ceil(totalCount / 5)}>
                      <RightOutlined/>
                    </S.PaginationButton>
                  </S.PaginationWrapper>
                </S.BlogListPanel>
              </div>
              <div className='right'>
                <div className='menu-wrapper'>
                  <div className='favourites'>
                    <p className='title'>{t('favourites')}</p>
                    <Menu
                      mode="inline"
                      openKeys={openKeys}
                      onOpenChange={onOpenChange}
                      onSelect={onMenuItemSelect}
                    >
                      {
                        categories.length > 0 && categories.map((item, index) => (
                          <SubMenu key={item.category} title={item.category}>
                            {
                              item?.subCategories.length
                              && item.subCategories.map((subCategory) => (
                                <Menu.Item key={`${item.category}.${subCategory}`}>{subCategory}</Menu.Item>
                              ))
                            }
                          </SubMenu>
                        ))
                      }
                    </Menu>
                  </div>
                  <div className='top-articles'>
                    <p className='title'>{t('topArticles')}</p>
                    <div className='top-articles-list'>
                      {
                        topArticles.length > 0 && topArticles.map((blog) => (
                          <div className='article-item' key={blog.id} onClick={() => gotoDetailPage(blog.id)}>
                            <div className='img-container'>
                              <img src={blog.img} alt={`blog-img-${blog.id}`} />
                            </div>
                            <div className='article-content'>
                              <p className='article-title'>{blog.title}</p>
                              <p className='article-description'>{blog.description}</p>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
                <div className='form'>
                  <p className='form-title'>{t('form.title')}</p>
                  <p className='form-description'>{t('form.description')}</p>
                  <S.Input placeholder={t('form.email')}/>
                  <Button>{t('form.send')}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </S.MainSection>
      <S.EllipseBackground>
        <div className='content'>
          <div className='big-ellipse'/>
        </div>
      </S.EllipseBackground>
    </React.Fragment>
  )
}

export default BlogPage
