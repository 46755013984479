import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import * as S from './styles'
import ContactUsSection from '../../components/ContactUs'
import WhatWeDoSection from '../../components/WhatWeDoSection'
import { LinkButton } from '../../components/Common/Button'
import { SectionTitle } from '../../components/Common/CommonStyled'
import { WorkflowDiagram } from '../../components/Common/Icon'
import { setTab } from '../../redux/actions/developmentActions'

import { getSelectedTab } from '../../selectors'

const WhatWeDoPage = () => {
  const { t } = useTranslation('whatWeDo')
  const dispatch = useDispatch()
  const selectedTab = useSelector(getSelectedTab)

  return (
    <React.Fragment>
      <S.WhatWeDoSection>
        <div className='container'>
          <div className='content'>
            <SectionTitle>{t('title')}</SectionTitle>
            <WhatWeDoSection activeTab={selectedTab} handleChangeTab={(tab) => dispatch(setTab(tab))}/>
          </div>
        </div>
      </S.WhatWeDoSection>
      <S.DevelopmentTypeSectionContainer>
        <S.WebDevelopmentSection active={selectedTab === 'web'}>
          <div className='container'>
            <div className='content'>
              <h2>{t('development.web.title')}</h2>
              <p className='summary'>{t('development.web.summary')}</p>
              <LinkButton to='/development/web'>{t('readMore')}</LinkButton>
            </div>
          </div>
        </S.WebDevelopmentSection>
        <S.MobileDevelopmentSection active={selectedTab === 'mobile'} positionAbsolute>
          <div className='container'>
            <div className='content'>
              <h2>{t('development.mobile.title')}</h2>
              <p className='summary'>{t('development.mobile.summary')}</p>
              <LinkButton to='/development/mobile'>{t('readMore')}</LinkButton>
            </div>
          </div>
        </S.MobileDevelopmentSection>
        <S.BlockchainDevelopmentSection active={selectedTab === 'blockchain'} positionAbsolute>
          <div className='container'>
            <div className='content'>
              <h2>{t('development.blockchain.title')}</h2>
              <p className='summary'>{t('development.blockchain.summary')}</p>
              <LinkButton to='/development/blockchain'>{t('readMore')}</LinkButton>
            </div>
          </div>
        </S.BlockchainDevelopmentSection>
        <S.GameDevelopmentSection active={selectedTab === 'game'} positionAbsolute>
          <div className='container'>
            <div className='content'>
              <h2>{t('development.game.title')}</h2>
              <p className='summary'>{t('development.game.summary')}</p>
              <LinkButton to='/development/game'>{t('readMore')}</LinkButton>
            </div>
          </div>
        </S.GameDevelopmentSection>
        <S.DesktopDevelopmentSection active={selectedTab === 'desktop'} positionAbsolute>
          <div className='container'>
            <div className='content'>
              <h2>{t('development.desktop.title')}</h2>
              <p className='summary'>{t('development.desktop.summary')}</p>
              <LinkButton to='/development/desktop'>{t('readMore')}</LinkButton>
            </div>
          </div>
        </S.DesktopDevelopmentSection>
      </S.DevelopmentTypeSectionContainer>
      <S.WorkFlowSection>
        <div className='container'>
          <div className='content'>
            <h2>
              {t('workflowDiagram')}
            </h2>
            <div className='workflow-diagram'>
              <WorkflowDiagram/>
            </div>
          </div>
        </div>
      </S.WorkFlowSection>
      <S.HelpUsSection>
        <div className='container'>
          <div className='content'>
            <h4>{t('helpUs')}</h4>
          </div>
        </div>
      </S.HelpUsSection>
      <ContactUsSection />
      <S.EllipseBackground>
        <div className='content development-page'>
          <div className='big-ellipse ellipse-1'/>
          <div className='small-ellipse ellipse-2'/>
          <div className='small-ellipse ellipse-3'/>
          <div className='big-ellipse ellipse-4'/>
        </div>
      </S.EllipseBackground>
    </React.Fragment>
  )
}

export default WhatWeDoPage
