import {
  WebCaseImg1,
  WebCaseImg2,
  WebCaseImg3,
  WebCaseImg4,
  MobileCaseImg1,
  MobileCaseImg2,
  MobileCaseImg3,
  MobileCaseImg4,
  GameCaseImg1,
  GameCaseImg2,
  GameCaseImg3,
  GameCaseImg4,
  DesktopCaseImg1,
  DesktopCaseImg2,
  DesktopCaseImg3,
  DesktopCaseImg4,
  BlockchainCaseImg1,
  BlockchainCaseImg2,
  BlockchainCaseImg3,
  BlockchainCaseImg4
} from '../../components/Common/Image'

export const SupportData = [
  {
    no: 1,
    title: 'support.stage1.title',
    description: 'support.stage1.description',
    subTitle: 'support.stage1.details.title',
    details: [
      'support.stage1.details.1',
      'support.stage1.details.2',
      'support.stage1.details.3'
    ]
  },
  {
    no: 2,
    title: 'support.stage2.title',
    description: 'support.stage2.description',
    subTitle: 'support.stage2.details.title',
    details: [
      'support.stage2.details.1',
      'support.stage2.details.2',
      'support.stage2.details.3'
    ]
  },
  {
    no: 3,
    title: 'support.stage3.title',
    description: 'support.stage3.description',
    subTitle: 'support.stage3.details.title',
    details: [
      'support.stage3.details.1',
      'support.stage3.details.2',
      'support.stage3.details.3'
    ]
  }
]

export const DevelopmentData = {
  web: {
    detail: [
      {
        title: 'development.web.detail.frontEndDevelopment.title',
        description: 'development.web.detail.frontEndDevelopment.content',
      },
      {
        title: 'development.web.detail.backEndDevelopment.title',
        description: 'development.web.detail.backEndDevelopment.content',
      },
      {
        title: 'development.web.detail.fullStackWebDevelopment.title',
        description: 'development.web.detail.fullStackWebDevelopment.content',
      },
      {
        title: 'development.web.detail.pwaAppDevelopment.title',
        description: 'development.web.detail.pwaAppDevelopment.content',
      },
      {
        title: 'development.web.detail.devOps.title',
        description: 'development.web.detail.devOps.content',
      }
    ],
    relatedCases:  [
      {
        img: WebCaseImg1,
        no: '01',
        title: 'development.web.relatedCases.1.title',
        description: 'development.web.relatedCases.1.description'
      },
      {
        img: WebCaseImg2,
        no: '02',
        title: 'development.web.relatedCases.2.title',
        description: 'development.web.relatedCases.2.description'
      },
      {
        img: WebCaseImg3,
        no: '03',
        title: 'development.web.relatedCases.1.title',
        description: 'development.web.relatedCases.1.description'
      },
      {
        img: WebCaseImg4,
        no: '04',
        title: 'development.web.relatedCases.2.title',
        description: 'development.web.relatedCases.2.description'
      }
    ]
  },
  mobile: {
    detail: [
      {
        title: 'development.mobile.detail.swiftKotlinNativeDevelopment.title',
        description: 'development.mobile.detail.swiftKotlinNativeDevelopment.content',
      },
      {
        title: 'development.mobile.detail.androidNativeDevelopment.title',
        description: 'development.mobile.detail.androidNativeDevelopment.content',
      },
      {
        title: 'development.mobile.detail.reactNativeDevelopment.title',
        description: 'development.mobile.detail.reactNativeDevelopment.content',
      },
      {
        title: 'development.mobile.detail.flutterDevelopment.title',
        description: 'development.mobile.detail.flutterDevelopment.content',
      }
    ],
    relatedCases: [
      {
        img: MobileCaseImg1,
        no: '01',
        title: 'development.mobile.relatedCases.1.title',
        description: 'development.mobile.relatedCases.1.description'
      },
      {
        img: MobileCaseImg2,
        no: '02',
        title: 'development.mobile.relatedCases.2.title',
        description: 'development.mobile.relatedCases.2.description'
      },
      {
        img: MobileCaseImg3,
        no: '03',
        title: 'development.mobile.relatedCases.1.title',
        description: 'development.mobile.relatedCases.1.description'
      },
      {
        img: MobileCaseImg4,
        no: '04',
        title: 'development.mobile.relatedCases.2.title',
        description: 'development.mobile.relatedCases.2.description'
      }
    ]
  },
  game: {
    detail: [
      {
        title: 'development.game.detail.unityGameDevelopment.title',
        description: 'development.game.detail.unityGameDevelopment.content',
      },
      {
        title: 'development.game.detail.cocosGameDevelopment.title',
        description: 'development.game.detail.cocosGameDevelopment.content',
      },
      {
        title: 'development.game.detail.unrealGameDevelopment.title',
        description: 'development.game.detail.unrealGameDevelopment.content',
      },
      {
        title: 'development.game.detail.htmlGameDevelopment.title',
        description: 'development.game.detail.htmlGameDevelopment.content',
      }
    ],
    relatedCases: [
      {
        img: GameCaseImg1,
        no: '01',
        title: 'development.game.relatedCases.1.title',
        description: 'development.game.relatedCases.1.description'
      },
      {
        img: GameCaseImg2,
        no: '02',
        title: 'development.game.relatedCases.2.title',
        description: 'development.game.relatedCases.2.description'
      },
      {
        img: GameCaseImg3,
        no: '03',
        title: 'development.game.relatedCases.1.title',
        description: 'development.game.relatedCases.1.description'
      },
      {
        img: GameCaseImg4,
        no: '04',
        title: 'development.game.relatedCases.2.title',
        description: 'development.game.relatedCases.2.description'
      }
    ]
  },
  desktop: {
    detail: [
      {
        title: 'development.desktop.detail.c++DesktopAppDevelopment.title',
        description: 'development.desktop.detail.c++DesktopAppDevelopment.content'
      },
      {
        title: 'development.desktop.detail.unityDesktopAppDevelopmentServices.title',
        description: 'development.desktop.detail.unityDesktopAppDevelopmentServices.content'
      }
    ],
    relatedCases: [
      {
        img: DesktopCaseImg1,
        no: '01',
        title: 'development.desktop.relatedCases.1.title',
        description: 'development.desktop.relatedCases.1.description'
      },
      {
        img: DesktopCaseImg2,
        no: '02',
        title: 'development.desktop.relatedCases.2.title',
        description: 'development.desktop.relatedCases.2.description'
      },
      {
        img: DesktopCaseImg3,
        no: '03',
        title: 'development.desktop.relatedCases.1.title',
        description: 'development.desktop.relatedCases.1.description'
      },
      {
        img: DesktopCaseImg4,
        no: '04',
        title: 'development.desktop.relatedCases.2.title',
        description: 'development.desktop.relatedCases.2.description'
      }
    ]
  },
  blockchain: {
    detail: [
      {
        title: 'development.blockchain.detail.icoDevelopment.title',
        description: 'development.blockchain.detail.icoDevelopment.content'
      },
      {
        title: 'development.blockchain.detail.walletDevelopment.title',
        description: 'development.blockchain.detail.walletDevelopment.content'
      },
      {
        title: 'development.blockchain.detail.exchangeSoftwareDevelopment.title',
        description: 'development.blockchain.detail.exchangeSoftwareDevelopment.content'
      },
      {
        title: 'development.blockchain.detail.decentralisedExchangeSoftware.title',
        description: 'development.blockchain.detail.decentralisedExchangeSoftware.content'
      },
      {
        title: 'development.blockchain.detail.customBlockchainServices.title',
        description: 'development.blockchain.detail.customBlockchainServices.content'
      }
    ],
    relatedCases: [
      {
        img: BlockchainCaseImg1,
        no: '01',
        title: 'development.blockchain.relatedCases.1.title',
        description: 'development.blockchain.relatedCases.1.description'
      },
      {
        img: BlockchainCaseImg2,
        no: '02',
        title: 'development.blockchain.relatedCases.2.title',
        description: 'development.blockchain.relatedCases.2.description'
      },
      {
        img: BlockchainCaseImg3,
        no: '03',
        title: 'development.blockchain.relatedCases.1.title',
        description: 'development.blockchain.relatedCases.1.description'
      },
      {
        img: BlockchainCaseImg4,
        no: '04',
        title: 'development.blockchain.relatedCases.2.title',
        description: 'development.blockchain.relatedCases.2.description'
      }
    ]
  }
}
