import React, { ReactNode } from 'react'

import { withCurrentRoute, ComponentWithCurrentRoute } from '../../utils/withCurrentRoute'
import * as S from './styles'
import PortfolioNavigation from './PortfolioNavigation'
import DevelopmentNavigation from './DevelopmentNavigation'

interface MenuItemProps extends ComponentWithCurrentRoute {
  name: string,
  to: string,
  active: boolean,
  children: ReactNode,
  subMenu?: boolean
}

const MenuItem = (props: MenuItemProps) => {
  const { name, to, active, children, subMenu } = props
  const [ anchorEl, setAnchorEl ] = React.useState(null)

  const handleClickMenuItem = (event) => {
    if (subMenu) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <S.MenuItemWrapper
      open={open}
      onMouseEnter={handleClickMenuItem}
      onMouseLeave={handleClose}
    >
      <S.MenuItem
        to={to}
        active={`${active}`}
      >
        { children }
      </S.MenuItem>
      {
        subMenu && open &&
        <div className='navigation-menu'>
          <div className='container'>
            <div className='content'>
              { name === 'whatWeDo' && <DevelopmentNavigation /> }
              { name === 'portfolio' && <PortfolioNavigation /> }
            </div>
          </div>
        </div>
      }
    </S.MenuItemWrapper>
  )
}

export default withCurrentRoute(MenuItem)
