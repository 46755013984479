import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import * as S from './styles'
import * as Icon from '../Common/Icon'

const WhatWeDoSubMenu = [
  {
    icon: <Icon.WebIcon />,
    name: 'whatWeDo.web',
    to: '/development/web'
  },
  {
    icon: <Icon.DesktopIcon />,
    name: 'whatWeDo.desktop',
    to: '/development/desktop'
  },
  {
    icon: <Icon.BlockchainIcon />,
    name: 'whatWeDo.blockchain',
    to: '/development/blockchain'
  },
  {
    icon: <Icon.MobileIcon />,
    name: 'whatWeDo.mobile',
    to: '/development/mobile'
  },
  {
    icon: <Icon.JoyStickIcon />,
    name: 'whatWeDo.game',
    to: '/development/game'
  }
]

const DevelopmentNavigation = () => {
  const { t } = useTranslation('menu')
  const location = useLocation()

  return (
    <S.DevelopmentSubmenu>
      {
        WhatWeDoSubMenu.map((item) => (
          <S.SubMenuItem key={item.name} active={location.pathname === item.to}>
            {
              item.to
                ?
                <Link to={item.to}>
                  {item.icon}
                  <span>{t(`${item.name}`)}</span>
                </Link>
                :
                <>
                  {item.icon}
                  <span>{t(`${item.name}`)}</span>
                </>
            }
          </S.SubMenuItem>
        ))
      }
    </S.DevelopmentSubmenu>
  )
}

export default DevelopmentNavigation
