import React from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import { Button } from '../Common/Button'

const ContactUsSection = () => {
  const { t } = useTranslation('contacts')

  return (
    <S.ContactUsSection>
      <div className='container'>
        <div className='content'>
          <S.Title>{t('title')}</S.Title>
          <div className='main-content'>
            <S.Left>
              <S.Input placeholder={t('input.name')} />
              <S.Input placeholder={t('input.email')} />
              <S.TextArea placeholder={t('input.enterText')} autoSize={{ minRows: 7, maxRows: 7 }} />
              <Button>{t('send')}</Button>
            </S.Left>
            <S.Right>
              <S.SubTitle>{t('requestEstimate.title')}</S.SubTitle>
              <S.Description>{t('requestEstimate.description')}</S.Description>
              {/*<S.SubTitle>{t('followUs')}</S.SubTitle>*/}
              {/*<div className='social-icons'>*/}
              {/*  <S.SocialIconButton type='link' href='#'><Icon.LinkedinIcon /></S.SocialIconButton>*/}
              {/*  <S.SocialIconButton type='link' href='#'><Icon.YoutubeIcon /></S.SocialIconButton>*/}
              {/*  <S.SocialIconButton type='link' href='#'><Icon.FacebookIcon /></S.SocialIconButton>*/}
              {/*  <S.SocialIconButton type='link' href='#'><Icon.TwitterIcon /></S.SocialIconButton>*/}
              {/*  <S.SocialIconButton type='link' href='#'><Icon.InstagramIcon /></S.SocialIconButton>*/}
              {/*</div>*/}
            </S.Right>
          </div>
        </div>
      </div>
    </S.ContactUsSection>

  )
}

export default ContactUsSection
