import styled from 'styled-components'
import { Drawer as MuiDrawer } from '@material-ui/core'
import { Link } from 'react-router-dom'

const Drawer = styled(MuiDrawer)`

  .MuiPaper-root {
    width: 300px;
    background: linear-gradient(90deg, ${props => props.theme.colors.dark}, ${props => props.theme.colors.darkBlue});

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-menu {
    background: linear-gradient(90deg, ${props => props.theme.colors.dark}, ${props => props.theme.colors.darkBlue}) !important;

    .ant-menu-sub {
      background: linear-gradient(90deg, ${props => props.theme.colors.dark}, ${props => props.theme.colors.darkBlue}) !important;
    }

    .ant-menu-item-selected {
      background-color: ${props => props.theme.colors.blue200} !important;
    }
  }
`

const MobileLogo = styled(Link)`
  margin: 20px auto;
  cursor: pointer;
`

export {
  Drawer,
  MobileLogo
}
