import React, { useRef } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import * as S from './styles'

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: false,
  autoplayTimeout: 10,
  slideBy: 'page',
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 1,
    },
    992: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

const Index = (props) => {
  const { children } = props;
  const carousel = useRef(null)

  const onPrev = () => {
    if (carousel && carousel.current) {
      // @ts-ignore
      carousel.current.prev();
    }
  }

  const onNext = () => {
    if (carousel && carousel.current) {
      // @ts-ignore
      carousel.current.next();
    }
  }

  return (
    <S.Container>
      <S.IconButton className='prev-button' onClick={onPrev} icon={<LeftOutlined />} />
      <OwlCarousel
        className='owl-carousel owl-theme'
        ref={carousel}
        {...options}
      >
        {
          children && children.map((chip) => chip)
        }
      </OwlCarousel>
      <S.IconButton className='next-button' onClick={onNext} icon={<RightOutlined />} />
    </S.Container>
  );
};

export default Index;
