export enum Color {
  white = '#FFFFFF',
  light = '#E1E6FF',
  light100 = '#e2e4e8',
  light200 = '#eef1ff',
  light300 = '#D8D8D8',
  black = '#000000',
  yellow = '#FFD300',
  dark = '#00041D',
  blue = '#6C81F7',
  red = '#F07F5F',
  green = '#73BE4F',
  orange = '#F5862D',
  purple = '#9446DC',
  darkBlue = '#1B0E50',
  blue50 = '#8794BA',
  blue100 = '#788AF8',
  blue200 = '#3A4AAF',
  blue300 = '#493f8b',
  blue400 = '#392F72',
  blue500 = '#1D293F',
  blue600 = '#08103F',
  blue700 = '#0B132A',
  blue800 = '#000212',
  grey = '#3A3A44',
  grey100 = '#2f3035',
  grey200 = '#5a5973',
  grey300 = '#3A3A3A',
  grey400 = '#616161',
  grey500 = '#9B9B9B',
  grey600 = '#7C8087',
  grey700 = '#898989'
}

const theme = {
  fontWeights: [0, 300, 400, 500, 600, 700],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
    button: 3,
  },
  colors: {
    white: Color.white,
    black: Color.black,
    yellow: Color.yellow,
    dark: Color.dark,
    darkBlue: Color.darkBlue,
    light100: Color.light100,
    light200: Color.light200,
    light300: Color.light300,
    blue50: Color.blue50,
    blue100: Color.blue100,
    blue200: Color.blue200,
    blue300: Color.blue300,
    blue400: Color.blue400,
    blue500: Color.blue500,
    blue600: Color.blue600,
    blue700: Color.blue700,
    blue800: Color.blue800,
    grey: Color.grey,
    grey100: Color.grey100,
    grey200: Color.grey200,
    grey300: Color.grey300,
    grey400: Color.grey400,
    grey500: Color.grey500,
    grey600: Color.grey600,
    grey700: Color.grey700,
    blue: Color.blue,
    red: Color.red,
    green: Color.green,
    orange: Color.orange,
    purple: Color.purple
  },
  buttonSizes: {
    medium: {
      fontSize: '1rem',
      height: '2.5rem',
    },
  },
  palette: {
    primary: {
      main: Color.yellow,
      light: Color.light,
      dark: Color.dark,
      contrastText: Color.white,
    },
    text: {
      primary: Color.black,
      secondary: Color.blue700,
      disabled: Color.grey,
      hint: Color.grey100,
    },
  },
}

export default theme
