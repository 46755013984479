import styled, { css } from 'styled-components'
import { Input as AInput, Pagination as APagination, Button as AButton } from 'antd'

const MainSection = styled.section`
  flex: 1;

  .content {
    padding: 120px 135px 120px;

    .header {
      .carousel-container {
        margin: 0 -11px;
      }
    }

    .body {
      display: flex;
      margin-top: 22px;

      .left {
        flex: 1;
        width: 0;
      }

      .right {
        width: 287px;
        display: flex;
        flex-direction: column;
        margin-left: 23px;
        justify-content: space-between;

        .menu-wrapper {
          display: grid;
          grid-template-columns: 1fr;
          grid-row-gap: 27px;

          .favourites {
            background: ${props => props.theme.colors.white};
            border-radius: 10px;
            overflow: hidden;
            padding: 30px;
            height: 393px;

            .title {
              font: 14px/18px CircularStd-Bold;
              color: ${props => props.theme.colors.blue200};
              text-transform: uppercase;
            }

            .ant-menu {
              border: none;

              .ant-menu-submenu-title {
                padding-left: 0 !important;
                font: 12px/38px CircularStd-Book;
                color: ${props => props.theme.colors.grey600};
                text-transform: uppercase;
                height: 35px;
                margin: 0;

                &:hover {
                  color: ${props => props.theme.colors.blue200};

                  i {
                    color: ${props => props.theme.colors.blue200} !important;
                  }
                }

                i {
                  right: 0 !important;
                }
              }

              .ant-menu-sub {
                background: transparent !important;

                .ant-menu-item {
                  padding-left: 20px !important;
                  margin: 0;
                  height: 30px;
                  font: 14px/30px CircularStd-Book;
                  color: ${props => props.theme.colors.grey600};

                  &:hover {
                    color: ${props => props.theme.colors.blue200};
                  }
                }

                .ant-menu-item-selected {
                  background: transparent !important;
                  color: ${props => props.theme.colors.blue200};

                  &::after {
                    display: none;
                  }
                }
              }

              .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
                color: ${props => props.theme.colors.blue200};
              }
            }
          }

          .top-articles {
            background: ${props => props.theme.colors.white};
            border-radius: 10px;
            overflow: hidden;
            padding: 30px;

            .title {
              font: 14px/18px CircularStd-Bold;
              color: ${props => props.theme.colors.blue200};
              text-transform: uppercase;
            }

            .top-articles-list {
              max-height: 625px;

              .article-item {
                display: flex;
                margin-bottom: 20px;
                cursor: pointer;
                transition: all ease 0.3s;

                &:hover {
                  opacity: 0.8;
                }

                &:last-child {
                  margin-bottom: 0;
                }

                .img-container {
                  img {
                    width: 55px;
                    height: 55px;
                  }
                }

                .article-content {
                  padding: 5px 8px;

                  .article-title {
                    font: 8px/10px CircularStd-Bold;
                    color: ${props => props.theme.colors.blue500};
                    margin-bottom: 6px;
                    text-transform: uppercase;
                  }

                  .article-description {
                    font: 8px/10px CircularStd-Bold;
                    color: ${props => props.theme.colors.grey600};
                    margin: 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -moz-box;
                    -moz-line-clamp: 2;
                    -moz-box-orient: vertical;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }

        .form {
          margin-top: 40px;

          .form-title {
            font: 14px/18px CircularStd-Bold;
            color: ${props => props.theme.colors.white};
            margin: 0;
            text-transform: uppercase;
          }

          .form-description {
            font: 16px/20px CircularStd-Book;
            color: ${props => props.theme.colors.blue50};
            margin: 15px 0 45px;
          }

          input {
            margin-bottom: 30px !important;
          }

          button {
            width: 100% !important;
          }
        }
      }
    }

    ${props => props.theme.breakpoints.down('lg')} {
      padding: 120px 0;
    }

    ${props => props.theme.breakpoints.down('sm')} {

      .carousel-container {
        margin: 0 20px !important;
      }

      .body {
        flex-direction: column-reverse;

        .right {
          margin-left: 0;
          width: 100% !important;

          .menu-wrapper {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 27px;

            .favourites {
              height: 100% !important;
            }
          }

          .form {
            margin: 40px auto;
            max-width: 500px;
            text-align: center;
          }
        }

        .left {
          width: 100%;
        }
      }
    }

    ${props => props.theme.breakpoints.down(600)} {
      .body {
        .right {
          .menu-wrapper {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
`

const BlogTypeCard = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  max-width: 365px;
  margin: 0 11px;
  cursor: pointer;

  .img-container {
    transition: opacity ease-in-out 0.3s;

    img {
      width: 100%;
      height: 190px;
    }
  }

  .card-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 27px;

    .name {
      font: 30px/40px CircularStd-Bold;
      color: ${props => props.theme.colors.white};
      margin: 0;
      text-transform: uppercase;
      text-align: center;
    }
  }

  &:hover {
    .img-container {
      opacity: 0.6;
    }
  }

  ${props => props.theme.breakpoints.down('md')} {
    max-width: unset !important;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    margin: 0 30px;
  }
`

const BlogListPanel = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.white};
  border-radius: 10px;
  overflow: hidden;
  padding: 60px;
  position: relative;
  min-height: 1000px;

  .heading {
    margin-bottom: 30px;

    .type {
      font: 20px/20px CircularStd-Bold;
      color: ${props => props.theme.colors.blue200};
      margin: 0;
      display: inline-block;
      text-transform: uppercase;
    }

    .category {
      font: 14px/20px CircularStd-Book;
      color: #545454;
      margin: 0 0 0 20px;
      display: inline-block;
    }

    ${props => props.theme.breakpoints.down(600)} {
      text-align: center;

      .category {
        margin: 0 !important;
      }
    }
  }

  .loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    z-index: 3;
  }

  .blog-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 25px;
    grid-column-gap: 30px;
  }

  ${props => props.theme.breakpoints.down(1200)} {
    padding: 30px;
  }

  ${props => props.theme.breakpoints.down(600)} {
    .blog-list {
      grid-template-columns: 1fr;

      > div {
        max-width: 340px !important;
        margin: 0 auto;
      }
    }
  }
`

const BlogCard = styled.div<{direction: 'vertical' | 'horizontal'}>`
  display: flex;
  height: 418px;

  .img-container {
    max-width: 340px;
    width: 100%;
    background-size: cover !important;
    background-position: 50% 50%;
    transition: 0.2s;
  }

  .content {
    background-color: ${props => props.theme.colors.light200};
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.2s;
    position: relative;
    overflow: hidden;

    .title {
      font: 16px/20px CircularStd-Bold;
      color: ${props => props.theme.colors.blue500};
      margin: 0;
      text-transform: uppercase;
      transition: 0.2s;
    }

    .description {
      font: 16px/20px CircularStd-Book;
      color: ${props => props.theme.colors.grey600};
      margin: 10px 0 13px;
      transition: 0.2s;
    }

    .date {
      font: 12px/18px CircularStd-Book;
      color: ${props => props.theme.colors.grey700};
      margin: 0;
      transition: 0.2s;
    }

    a {
      position: absolute;
      bottom: -40px;
      left: calc(50% - 105px);
      opacity: 0;
      transition: 0.2s;
    }
  }

  ${props => props.direction === 'vertical' && css`
    flex-direction: column;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    border: 3px solid transparent;

    .img-container {
      height: 250px;
    }

    .content {
      padding: 18px 16px;

      .description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;
      }
    }

    &:hover {
      border: 3px solid ${props => props.theme.colors.blue300};

      .img-container {
        height: 170px !important;
      }

      .content {
        padding: 47px 13px 35px;
        background: linear-gradient(125deg, ${props => props.theme.colors.blue600}, ${props => props.theme.colors.blue400});

        .title {
          font: 18px/30px CircularStd-Bold;
          color: ${props => props.theme.colors.white};
          text-align: center;
        }

        .description, .date {
          opacity: 0;
          height: 0 !important;
        }

        a {
          bottom: 35px;
          opacity: 1;
        }
      }
    }

    ${props => props.theme.breakpoints.down('lg')} {
      height: 437px;
    }

    ${props => props.theme.breakpoints.down(600)} {
      .title, .description {
        text-align: center;
      }
    }
  `}

  ${props => props.direction === 'horizontal' && css`
    flex-direction: row;
    grid-column: 1 / span 2;
    height: 293px !important;

    .img-container {
      width: 340px;
    }

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 30px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      .description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 7;
        -moz-box-orient: vertical;
      }

      .date {
        margin: auto 0 0;
      }
    }

    &:hover {
      .content {
        border: 3px solid ${props => props.theme.colors.blue300};
        padding: 47px 30px 35px;
        background: linear-gradient(125deg, ${props => props.theme.colors.blue600}, ${props => props.theme.colors.blue400});

        .title {
          font: 18px/30px CircularStd-Bold;
          color: ${props => props.theme.colors.white};
          text-align: center;
        }

        .description, .date {
          opacity: 0;
          height: 0 !important;
        }

        a {
          bottom: 35px;
          opacity: 1;
        }
      }
    }

    ${props => props.theme.breakpoints.down('lg')} {
      height: 320px;

      .img-container {
        width: calc(50% - 13px) !important;
      }

      .description {
        -webkit-line-clamp: 5 !important;
        -moz-line-clamp: 5 !important;
      }
    }
  `}
`

const Input = styled(AInput)`
  background-color: ${props => props.theme.colors.grey200} !important;
  border: none !important;
  color: ${props => props.theme.colors.white} !important;
  padding: 8px 20px !important;
  margin-bottom: 20px !important;
  border-radius: 6px !important;
  font: 14px/30px Roboto-Light !important;
`

const PaginationWrapper = styled.div<{disabled: boolean}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  ${props => props.disabled && `
    opacity: 0;
    pointer-events: none;
  `}

  ${props => props.theme.breakpoints.down(500)} {
    margin: 40px -20px 0;
    min-width: 100%;
    width: unset;
    justify-content: center;
  }
`

const Pagination = styled(APagination)`
  .ant-pagination-next, .ant-pagination-prev {
    display: none !important;
  }

  .ant-pagination-jump-prev, .ant-pagination-jump-next {

    svg {
      fill: ${props => props.theme.colors.yellow} !important;
    }
  }

  .ant-pagination-item {
    min-width: 35px !important;
    border: none !important;
    background: #FBFBFB !important;
    margin-right: 5px !important;

    a {
      color: #BDBDBD !important;
    }

    ${props => props.theme.breakpoints.down(500)} {
      min-width: 25px !important;
      width: 25px !important;
      height: 25px !important;
      margin-right: 2px;

      a {
        font: 12px/25px CircularStd-Book;
        padding: 0 !important;
      }
    }
  }

  .ant-pagination-item-active {
    border: 1px solid ${props => props.theme.colors.yellow} !important;

    a {
      color: ${props => props.theme.colors.yellow} !important;
    }
  }
`

const PaginationButton = styled(AButton)`
  width: 26px !important;
  height: 26px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.white} !important;
  box-shadow: 0 4px 14px 1px rgba(0,0,0,0.16) !important;
  padding: 0 !important;
  border: none !important;

  svg {
    fill: ${props => props.theme.colors.blue200};
    width: 13px;
    height: 13px;
    margin-top: 3px;
  }

  &:disabled {
    svg {
      fill: ${props => props.theme.colors.light200} !important;
    }
  }
`

const EllipseBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;

  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;

    .big-ellipse {
      width: 986.69px;
      height: 986.69px;
      background: rgba(255, 255, 255, 0.05);
      filter: blur(24px);
      border-radius: 50%;
      position: absolute;
      top: 650px;
      left: 54px;
    }
  }
`

export {
  MainSection,
  EllipseBackground,
  BlogTypeCard,
  BlogListPanel,
  BlogCard,
  Input,
  Pagination,
  PaginationButton,
  PaginationWrapper
}
