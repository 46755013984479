import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import LayoutRoute from './components/LayoutRoute'

import MainPage from './pages/Main'
import WhatWeDoPage from './pages/WhatWeDo'
import WebDevelopmentPage from './pages/WhatWeDo/Developments/Web'
import MobileDevelopmentPage from './pages/WhatWeDo/Developments/Mobile'
import GameDevelopmentPage from './pages/WhatWeDo/Developments/Game'
import DesktopDevelopmentPage from './pages/WhatWeDo/Developments/Desktop'
import BlockchainDevelopmentPage from './pages/WhatWeDo/Developments/Blockchain'
import OurExpertisePage from './pages/OurExpertise'
import PortfolioPage from './pages/Portfolio'
import CompanyPage from './pages/Company'
import ContactUsPage from './pages/Contacts'
import BlogPage from './pages/Blog'
import BlogDetailPage from './pages/Blog/BlogDetail'

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <LayoutRoute path='/' exact component={MainPage} hideHeaderBg/>
        <LayoutRoute path='/development' exact component={WhatWeDoPage} />
        <LayoutRoute path='/development/web' exact component={WebDevelopmentPage} />
        <LayoutRoute path='/development/mobile' exact component={MobileDevelopmentPage} />
        <LayoutRoute path='/development/game' exact component={GameDevelopmentPage} />
        <LayoutRoute path='/development/desktop' exact component={DesktopDevelopmentPage} />
        <LayoutRoute path='/development/blockchain' exact component={BlockchainDevelopmentPage} />
        <LayoutRoute path='/expertise' component={OurExpertisePage} />
        <LayoutRoute path='/portfolio' exact component={PortfolioPage} />
        <LayoutRoute path='/company' component={CompanyPage} />
        <LayoutRoute path='/contact-us' exact component={ContactUsPage} />
        <LayoutRoute path='/blog' exact component={BlogPage} />
        <LayoutRoute path='/blog/:id' exact component={BlogDetailPage} hideHeaderBg/>
      </Switch>
    </Router>
  )
}

export default AppRouter
