import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import ContactUsSection from '../../components/ContactUs'
import { CheckCircleIcon } from '../../components/Common/Icon'

import * as S from './styles'
import { SectionTitle } from '../../components/Common/CommonStyled'
import { DeliverImg, ListenImg, QuestionImg } from '../../components/Common/Image'
import ManagementSlider from '../../components/ManagementSlider'
import { managementData } from './data'

const CompanyPage = () => {
  const { t } = useTranslation('company')
  const location = useLocation()

  useEffect(() => {
    const pathname = location.pathname.split('/')
    const sectionId = pathname.length > 2 ? pathname.pop() : null
    if (sectionId) {
      const section = document.getElementById(sectionId)
      if (!section) {
        return
      }
      section.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"})
    }
  }, [location])

  return (
    <React.Fragment>
      <S.CompanySection>
        <div className='container'>
          <div className='content'>
            <SectionTitle>{t(`title`)}</SectionTitle>
            <h2>{t('description')}</h2>
          </div>
        </div>
      </S.CompanySection>
      <S.OurValuesSection id='values'>
        <div className='container'>
          <div className='content'>
            <h3 className='title'>{t('ourValues.title')}</h3>
            <p className='description'>{t('ourValues.description')}</p>
            <div className='values-list'>
              <div className='value-item'>
                <CheckCircleIcon />
                <span>{t('ourValues.values.1')}</span>
              </div>
              <div className='value-item'>
                <CheckCircleIcon />
                <span>{t('ourValues.values.2')}</span>
              </div>
              <div className='value-item'>
                <CheckCircleIcon />
                <span>{t('ourValues.values.3')}</span>
              </div>
              <div className='value-item'>
                <CheckCircleIcon />
                <span>{t('ourValues.values.4')}</span>
              </div>
              <div className='value-item'>
                <CheckCircleIcon />
                <span>{t('ourValues.values.5')}</span>
              </div>
            </div>
          </div>
        </div>
      </S.OurValuesSection>
      <S.ManagementSection id='management'>
        <div className='container'>
          <div className='content'>
            <h3 className='title'>{t('management.title')}</h3>
            <ManagementSlider data={managementData} />
          </div>
        </div>
      </S.ManagementSection>
      <S.OurPrinciplesSection id='principles'>
        <div className='container'>
          <div className='content'>
            <h3 className='title'>{t('ourPrinciples.title')}</h3>
            <p className='description'>{t('ourPrinciples.description')}</p>
            <div className='principle-card-list'>
              <S.PrincipleCard>
                <div className='header'>
                  <h6 className='title'>{t('ourPrinciples.listen')}</h6>
                  <div className='divider' />
                </div>
                <div className='img-container'>
                  <img src={ListenImg} alt='listen-img' />
                </div>
              </S.PrincipleCard>
              <S.PrincipleCard>
                <div className='header'>
                  <h6 className='title'>{t('ourPrinciples.question')}</h6>
                  <div className='divider' />
                </div>
                <div className='img-container'>
                  <img src={QuestionImg} alt='question-img' />
                </div>
              </S.PrincipleCard>
              <S.PrincipleCard>
                <div className='header'>
                  <h6 className='title'>{t('ourPrinciples.deliver')}</h6>
                  <div className='divider' />
                </div>
                <div className='img-container'>
                  <img src={DeliverImg} alt='deliver-img' />
                </div>
              </S.PrincipleCard>
            </div>
          </div>
        </div>
      </S.OurPrinciplesSection>
      <ContactUsSection />
      <S.EllipseBackground>
        <div className='content'>
          <div className='small-ellipse ellipse-1'/>
          <div className='small-ellipse ellipse-2'/>
          <div className='big-ellipse ellipse-3'/>
          <div className='big-ellipse ellipse-4'/>
          <div className='big-ellipse ellipse-5'/>
        </div>
      </S.EllipseBackground>
    </React.Fragment>
  )
}

export default CompanyPage
