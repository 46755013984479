import {
  BlockchainBlogImg,
  MobileBlogImg,
  WebBlogImg
} from '../../components/Common/Image'

export const BlogTypes = [
  {
    type: 'mobile',
    img: MobileBlogImg
  },
  {
    type: 'web',
    img: WebBlogImg
  },
  {
    type: 'blockchain',
    img: BlockchainBlogImg
  },
  {
    type: 'desktop',
    img: WebBlogImg
  },
  {
    type: 'game',
    img: BlockchainBlogImg
  }
]
