import styled from "styled-components";

const Container = styled.section`
  .content {
    padding: 0 150px;
    text-align: center;

    h3 {
      font: 32px/45px Roboto-Bold;
      color: ${props => props.theme.colors.white};
      text-transform: uppercase;
      margin-bottom: 80px;
    }

    ${props => props.theme.breakpoints.down("lg")} {
      padding: 0;
    }

    .stage-card-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 14px;

      ${props => props.theme.breakpoints.down("sm")} {
        grid-template-columns: 1fr;

        > div {
          max-width: 372px;
          margin: 0 auto;
        }
      }
    }
  }
`

const StageCard = styled.div`
  padding: 50px;
  position: relative;
  text-align: left;
  background: #05052D;
  box-shadow: 0 18px 50px -15px rgba(25,42,89,0.2);
  border-radius: 10px;

  .title {
    font: 26px/35px Roboto-Bold;
    color: ${props => props.theme.colors.white};
    text-transform: uppercase;
  }

  .description {
    font: 18px/27px Roboto-Regular;
    color: ${props => props.theme.colors.blue50};
    min-height: 162px;
  }

  .divider {
    width: 100%;
    height: 3px;
    background: #2A407C;
    margin: 15px 0 20px;
  }

  .sub-title {
    font: 18px/20px Roboto-Medium;
    color: ${props => props.theme.colors.white};
  }

  .number {
    position: absolute;
    font: 40px/53px Roboto-Bold;
    color: ${props => props.theme.colors.yellow} !important;
    right: 26px;
    top: 26px;
    margin: 0;
  }

  ul {
    padding-left: 0;

    li {
      font: 16px/22px Roboto-Regular;
      color: ${props => props.theme.colors.blue50};
    }
  }

  ${props => props.theme.breakpoints.down("sm")} {
    .title, .description, .sub-title {
      text-align: center;
    }
  }
`

export { Container, StageCard }
