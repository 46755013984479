import styled from 'styled-components'

const SectionTitle = styled.h1`
  font: 48px/55px Roboto-Bold;
  color: white;
  text-transform: uppercase;
  text-align: center;

  ${props => props.theme.breakpoints.down("sm")} {
    font: 40px/48px Roboto-Bold;
  }
`

export {
  SectionTitle
}
