import styled from 'styled-components'
import { Button } from 'antd'

const Container = styled.div`
  position: relative;
`

const IconButton = styled(Button)`
  width: 26px !important;
  height: 26px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${props => props.theme.colors.blue200};
    width: 13px;
    height: 13px;
    margin-top: 3px;
  }

  &.prev-button {
    position: absolute;
    left: -50px;
    top: calc(50% - 13px);
  }

  &.next-button {
    position: absolute;
    right: -50px;
    top: calc(50% - 13px);
  }

  ${props => props.theme.breakpoints.down(500)} {
    &.prev-button {
      left: -32px;
    }

    &.next-button {
      right: -32px;
    }
  }
`

export { Container, IconButton }
