import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Spin } from 'antd'
import { Zoom } from '@material-ui/core'

import * as S from './styles'
import { SectionTitle } from '../../components/Common/CommonStyled'
import ContactUsSection from '../../components/ContactUs'

import { PortfolioData } from './data'
import { NoDataImg } from '../../components/Common/Image'
import {DevelopmentType, IndustryType} from '../../shared/enums'

const PortfolioPage = () => {
  const { t } = useTranslation('portfolio')

  const location = useLocation()

  const [ loading, setLoading ] = useState(true)
  const [ data, setData ] = useState(PortfolioData)
  const [ category, setCategory ] = useState<DevelopmentType | null>(null)
  const [ industry, setIndustry ] = useState<IndustryType | null>(null)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setCategory(params.get('category') as DevelopmentType)
    setIndustry(params.get('industry') as IndustryType)
  }, [location])

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      const filteredPortfolios = PortfolioData.filter(item =>
        (!category || (typeof item.category === 'object' ? item.category.indexOf(category) > -1 : item.category === category)) &&
        (!industry || (typeof item.industry === 'object' ? item.industry.indexOf(industry) > -1 : item.industry === industry)))
      setData(filteredPortfolios)
      setLoading(false)
    }, 500)
  }, [category, industry])

  const getCategoryAndIndustry = useCallback(() => {
    let text = ''
    if (category) {
      text += `CATEGORY: ${category}`
    }

    if (industry) {
      if (category) {
        text += ', '
      }
      text += `INDUSTRY: ${industry}`
    }
    return <p className='sub-header'>{text}</p>
  }, [category, industry])

  return (
    <React.Fragment>
      <S.MainSection>
        <div className='container'>
          <div className='content'>
            <SectionTitle>{t(`title`)}</SectionTitle>
            { getCategoryAndIndustry() }
            {
              loading &&
              <div className='loading-container'>
                <Spin
                  spinning={ true }
                  size='large'
                />
              </div>
            }
            <Zoom in={!loading} timeout={{enter: 500, exit: 0}}>
              {
                data.length > 0
                ?
                  <div className='portfolio-card-list'>
                    {
                      data.map((portfolio, index) => (
                        <S.PortfolioCard key={index}>
                          <div className='img-container'>
                            <img src={portfolio.img} alt={`portfolio-${index}`} />
                          </div>
                          <div className='card-content'>
                            <div className='summary'>
                              <h3 className='title'>{ portfolio.title }</h3>
                            </div>
                          </div>
                          <div className='card-hovered'>
                            <h4 className='title'>{ portfolio.title }</h4>
                            <p className='description'>{ portfolio.description }</p>
                          </div>
                        </S.PortfolioCard>
                      ))
                    }
                  </div>
                :
                  <div className='no-data'>
                    <img src={NoDataImg} alt='adsf' />
                    <h5>{t('noData')}</h5>
                  </div>
              }
            </Zoom>
          </div>
        </div>
      </S.MainSection>
      <ContactUsSection />
      <S.EllipseBackground>
        <div className='content'>
          <div className='small-ellipse ellipse-1'/>
          <div className='small-ellipse ellipse-2'/>
          <div className='big-ellipse ellipse-3'/>
        </div>
      </S.EllipseBackground>
    </React.Fragment>
  )
}

export default PortfolioPage
