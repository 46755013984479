import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Hidden } from '@material-ui/core'

import * as S from '../styles'
import ContactUsSection from '../../../components/ContactUs'
import DevelopmentDetail from '../../../components/DevelopmentDetail'
import FeatureCarousel from '../../../components/FeatureCarousel'
import SupportSection from '../../../components/SupportSection'
import { SectionTitle } from '../../../components/Common/CommonStyled'
import { DevelopmentData } from '../data'

const GameDevelopment = () => {
  const { t } = useTranslation('whatWeDo')
  const [ activeRelatedCaseIndex, setActiveRelatedCaseIndex ] = useState(0)

  return (
    <React.Fragment>
      <S.GameDevelopmentSection active>
        <div className='container'>
          <div className='content'>
            <SectionTitle>{t('development.game.title')}</SectionTitle>
            <DevelopmentDetail data={DevelopmentData.game.detail}/>
          </div>
        </div>
      </S.GameDevelopmentSection>
      <S.RelatedCasesSection>
        <div className='container'>
          <div className='content'>
            <Hidden smDown>
              <div className='left'>
                <h3>{t('development.game.relatedCases.title')}</h3>
                <p className='sub-title'>{DevelopmentData.game.relatedCases[activeRelatedCaseIndex].no} - {t(DevelopmentData.game.relatedCases[activeRelatedCaseIndex].title)}</p>
                <p className='description'>{t(DevelopmentData.game.relatedCases[activeRelatedCaseIndex].description)}</p>
              </div>
              <FeatureCarousel
                data={DevelopmentData.game.relatedCases}
                onChangeActiveItemIndex={(index) => setActiveRelatedCaseIndex(index)}
                startIndex={1}
                translationNs='whatWeDo'
              />
            </Hidden>
            <Hidden mdUp>
              <div className='left'>
                <h3>{t('development.web.relatedCases.title')}</h3>
              </div>
              <FeatureCarousel
                data={DevelopmentData.game.relatedCases}
                translationNs='whatWeDo'
              />
            </Hidden>
          </div>
        </div>
      </S.RelatedCasesSection>
      <SupportSection />
      <ContactUsSection />
      <S.EllipseBackground>
        <div className='content development-detail-page'>
          <div className='big-ellipse ellipse-1'/>
          <div className='small-ellipse ellipse-2'/>
          <div className='small-ellipse ellipse-3'/>
          <div className='big-ellipse ellipse-4'/>
          <div className='small-ellipse ellipse-5'/>
        </div>
      </S.EllipseBackground>
    </React.Fragment>
  )
}

export default GameDevelopment
