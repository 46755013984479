import styled, { css } from 'styled-components'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

import { HeaderBgImg } from '../Common/Image'

interface MenuItemWrapperProps {
  open?: boolean
}

interface MenuItemProps {
  active?: string
}

interface SubMenuItemProps {
  active?: boolean
}

interface IHeaderSectionProps {
  hideHeaderBg: boolean,
  reachStart: boolean
}

const HeaderSection = styled.div<IHeaderSectionProps>`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10 !important;
  transition: background-color ease-in-out 0.3s;

  ${props => !props.hideHeaderBg && css`
    height: 130px;
    background-image: url(${HeaderBgImg});
    background-position: 50% 65%;
    background-size: cover;
  `}

  ${props => props.hideHeaderBg && !props.reachStart && css`
    background-color: ${props => props.theme.colors.blue800};
  `}
`

const Content = styled.div`
  width: 100%;
  padding: 35px 45px 0;
  display: flex;
  height: 130px;

  ${props => props.theme.breakpoints.down("lg")} {
    padding: 35px 0 0;
  }

  > a {
    padding: 0 30px;

    ${props => props.theme.breakpoints.down('md')} {
      padding: 0 10px;
    }
  }

  .menu-wrapper {
    flex-grow: 1;
    width: 0;

    &.left {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      > div {
        margin-left: 0;
        margin-right: auto;
      }
    }

    &.right {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      > div {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
`

const MenuItemWrapper = styled.div<MenuItemWrapperProps>`
  display: flex;
  width: 100%;
  transition: 0.1s;

  ${props => props.open && `
    margin-bottom: 0;
    background: linear-gradient(0deg, #1B0E50, #150960);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  `}

  &:hover {
    .indicator {
      width: 100% !important;
    }

    ${props => props.open && `
      .navigation-menu {
        opacity: 1;
      }
    `}

  }

  .navigation-menu {
    position: absolute;
    left: 0;
    top: 130px;
    width: 100vw;
    background: #1B0E50;
    opacity: 0;

    .content {
      padding: 25px 135px;

      ${props => props.theme.breakpoints.down('lg')} {
        padding: 25px 35px;
      }

      ${props => props.theme.breakpoints.down("md")} {
        padding: 25px 20px;
      }
    }
  }
`

const MenuItem = styled(Link)<MenuItemProps>`
  color: ${props => props.theme.colors.white} !important;
  font: 16px CircularStd-Medium;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: uppercase;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  margin: auto;
  white-space: nowrap;

  ${props => props.active === 'true' && `
    .indicator {
      width: 100% !important;
    }
  `}

  .indicator {
    height: 4px;
    width: 0;
    margin-top: 5px;
    background-color: ${props => props.theme.colors.yellow};
    transition: 0.2s;
  }

  &:hover {
    .indicator {
      width: 100%;
    }
  }
`

const MobileContainer = styled.div`
  width: 100%;
  padding: 30px 20px 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 130px;
`

const MenuButton = styled(Button)`
  background: transparent !important;
  border: none !important;
  padding: 0 !important;

  svg {
    fill: ${props => props.theme.colors.white};
    width: 30px;
    height: 30px;
    transition: 0.2s;

    &:hover {
      fill: ${props => props.theme.colors.yellow};
    }
  }
`

const DevelopmentSubmenu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 15px;
  max-width: 750px;
  height: 99px;
`

const PortfolioSubmenu = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  span {
    margin-left: 10px !important;
    text-transform: initial !important;
  }

  h5 {
    font: 14px/19px CircularStd-Medium;
    color: ${props => props.theme.colors.white};
    margin-bottom: 25px;
    text-align: center;
    text-transform: uppercase;
  }

  .category {
    .category-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 15px;
      border-right: 1px solid ${props => props.theme.colors.white};
      margin-right: 40px;
    }
  }

  .industry {
    .industry-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-row-gap: 15px;
    }
  }

  ${props => props.theme.breakpoints.down('md')} {
    .category-list {
      padding-right: 10px;
      margin-right: 23px !important;
    }
  }
`

const SubMenuItem = styled.div<SubMenuItemProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
  }

  svg {
    fill: ${props => props.theme.colors.white};
    transition: 0.2s;
  }

  span {
    font: 14px/19px CircularStd-Medium;
    color: ${props => props.theme.colors.white};
    text-transform: uppercase;
    white-space: nowrap;
    margin-left: 20px;
    transition: 0.2s;
  }

  &:hover {
    svg {
      fill: ${props => props.theme.colors.yellow};
    }

    span {
      color: ${props => props.theme.colors.yellow};
    }
  }

  ${props => props.active && `
    svg {
      fill: ${props.theme.colors.yellow};
    }

    span {
      color: ${props.theme.colors.yellow};
    }
  `}
`

export {
  HeaderSection,
  Content,
  MenuItemWrapper,
  MenuItem,
  MobileContainer,
  MenuButton,
  SubMenuItem,
  DevelopmentSubmenu,
  PortfolioSubmenu
}
