import React, { useState } from 'react'
import { OutlinedButton } from '../Common/Button'
import { useTranslation } from 'react-i18next'
import { ClickAwayListener } from '@material-ui/core'

import * as S from './styles'

interface ExpertCardProps {
  img: string
  name: string
  job: string
  description: string
  skills: string[]
  projects: string[]
}

const ExpertCard = (props: ExpertCardProps) => {
  const { t } = useTranslation('expertise')
  const [ showBackSide, setShowBackSide ] = useState(false)

  return (
    <ClickAwayListener onClickAway={() => setShowBackSide(false)}>
      {
        !showBackSide
          ?
          <S.Card key={1}>
            <img src={props.img} alt='expertImg' />
            <h5 className='name'>{props.name}</h5>
            <p className='job'>{t(`${props.job}`)}</p>
            <p className='description'>{t(`${props.description}`)}</p>
            <div className='footer'>
              <OutlinedButton onClick={() => setShowBackSide(true)}>{t('viewMore')}</OutlinedButton>
            </div>
          </S.Card>
          :
          <S.Card key={2} className='back-side'>
            <h5 className='name'>{props.name}</h5>
            <p className='job'>{t(`${props.job}`)}</p>
            <h6>{t('technicalSkills')}:</h6>
            <p className='skill'>{props.skills.join(', ')}</p>
            <h6>{t('projects')}:</h6>
            <ul>
              {
                props.projects.map((project: string) => (
                  <li key={project}>{project}</li>
                ))
              }
            </ul>
            <div className='footer'>
              <OutlinedButton onClick={() => setShowBackSide(false)}>{t('back')}</OutlinedButton>
            </div>
          </S.Card>
      }
    </ClickAwayListener>
  )
}

export default ExpertCard
