import React, { ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import * as S from './styles'
import Header from '../Header'
import Footer from '../Footer'

export interface LayoutProps {
  children?: ReactNode
  isAnonym: boolean,
  hideHeaderBg: boolean
}

const Layout = ({ children, isAnonym, hideHeaderBg }: LayoutProps) => {
  const location = useLocation()
  const [ reachStart, setReachStart ] = useState(true)

  const gotoTop = (smooth?: boolean) => {
    const scrollDiv = document.getElementById('scroll-div')
    if (scrollDiv) {
      scrollDiv.style.scrollBehavior = smooth ? 'smooth' : 'initial'
      scrollDiv.scrollTop = 0
    }
  }

  useEffect(() => {
    gotoTop()
  }, [location])

  return (
    <S.Root>
      <S.AppContent>
        <S.MainContent>
          <S.Container
            id='scroll-div'
            onYReachStart={() => setReachStart(true)}
            onScrollY={(e) => setReachStart(false)}
          >
            <Header hideHeaderBg={hideHeaderBg} reachStart={reachStart}/>
            {children}
            <Footer />
          </S.Container>
        </S.MainContent>
      </S.AppContent>
    </S.Root>
  )
}

Layout.defaultProps = {
  isAnonym: false
}

export default Layout
